export const API_ERROR = 'API_ERROR';
export const REF_VALUE = 'REF_VALUE';
export const ROLE_VALUE = 'ROLE_VALUE';
export const API_ERROR_RESET = 'API_ERROR_RESET';
export const IS_LOADER = 'IS_LOADER';
export const LEFT_ARROW = 'LEFT_ARROW'; 
export const RIGHT_ARROW = 'RIGHT_ARROW';
export const ACTIVE_GEO_FILTERS = 'ACTIVE_GEO_FILTERS';
export const CURRENT_GEO_REF = 'CURRENT_GEO_REF';
export const SORTING_FLAG = 'SORTING_FLAG';
export const BENEFICIARY_ACTIVE_FILTER_STATUS = 'BENEFICIARY_ACTIVE_FILTER_STATUS';
export const BREADCRUMB_DATA = 'BREADCRUMB_DATA';
export const MISSION_TASK_REGENERATE = 'MISSION_TASK_REGENERATE';

export const IMAGE_DATA = 'IMAGE_DATA';
