import React, { useEffect, useState } from 'react'
import './style.scss'
import BlueLeftArrow from '../../../../assets/images/BlueLeftArrow.svg';
import DeleteIcon from '../../../../assets/images/delete-with-background.svg';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../../storeManager/modal/actions';
import { MODALS } from '../../../../storeManager/modal/constants';
import CustomModal from '../../../../components/Common/Modal';
import CustomDropdown from '../../../../components/Common/CustomDropdown';
import { commonService } from '../../../../services/common';
import GlobalConfig from '../../../../config/globalconfig';
import { setApiError } from '../../../../storeManager/common/actions';
import { catchErrorHandler } from '../../../../services/commonUtils';
import SuperAdminImageWithoutToken from '../../SuperAdminImageWithoutToken';
import dummyImg from '../../../../assets/images/defaultLivelihoodIcon.png'

const CreateLivelihood = ({ handleCreateLivelihoodPage, isEdit, editData, setToastMessage }) => {

    const [livelihoodName, setLivelihoodName] = useState('');
    const [livelihoodIcon, setLivelihoodIcon] = useState('');
    const [livelihoodDisplayName, setLivelihoodDisplayName] = useState('');
    const [assetType, setAssetType] = useState('');
    const [productType, setProductType] = useState('');
    const [eventName, setEventName] = useState([]);
    const [typeEventName, setTypeEventName] = useState('');
    const [eventType, setEventType] = useState([]);
    const [selectEventType, setSelectEventType] = useState('');

    const [assetTypes, setAssetTypes] = useState([]);
    const [productTypes, setProductTypes] = useState([]);
    const [events, setEvents] = useState([]);
    const [image, setImage] = useState(null);
    const [imageName, setImageName] = useState(null);
    const [isdefaultImg, setIsdefaultImg] = useState('');
    const [eventListData, setEventListData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [livelihoodId, setLivelihoodId] = useState();
    const [assetDropDownListData, setAssetDropDownListData] = useState([]);
    const [ProductDropDownListData, setProductDropDownListData] = useState([]);
    const [eventNameDropDownListData, setEventNameDropDownListData] = useState([]);
    const [livelihoodNameDropDownListData, setLivelihoodNameDropDownListData] = useState([]);

    const dispatch = useDispatch();

    const eventTypeOptionList = async () => {
        setIsLoading(true)
        try {
            const response = await commonService.eventTypeOptionList();
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                const updatedData = res?.data.map(item => ({
                    ...item,
                    value: item?.name,
                    name: item?.displayName
                }));
                let newupdatedData = updatedData.sort((a, b) => {
                    if (a.name < b.name) {
                      return -1;
                    }
                    if (a.name > b.name) {
                      return 1;
                    }
                    return 0;
                  });
                setEventListData(newupdatedData)
            }
            else {
                dispatch(setApiError())
                setEventListData([])
            }
            setIsLoading(false)
        } catch (error) {
            setEventListData([])
            console.error(error)
            catchErrorHandler(error, dispatch(setApiError()));
            setIsLoading(false)
        }
    };
    const assetTypeOptionList = async () => {
        setIsLoading(true)
        try {
            const response = await commonService.assetTypeOptionList();
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                const updatedData = res?.data.map(item => ({
                    ...item,
                    value: item?.name,
                    name: item?.displayName
                }));
                let newupdatedData = updatedData.sort((a, b) => {
                    if (a.name < b.name) {
                      return -1;
                    }
                    if (a.name > b.name) {
                      return 1;
                    }
                    return 0;
                  });
                  setAssetDropDownListData(newupdatedData)
            }
            else {
                dispatch(setApiError())
                setAssetDropDownListData([])
            }
            setIsLoading(false)
        } catch (error) {
            setAssetDropDownListData([])
            console.error(error)
            catchErrorHandler(error, dispatch(setApiError()));
            setIsLoading(false)
        }
    };
    const productTypeOptionList = async () => {
        setIsLoading(true)
        try {
            const response = await commonService.productTypeOptionList();
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                const updatedData = res?.data.map(item => ({
                    ...item,
                    value: item?.name,
                    name: item?.displayName
                }));
                let newupdatedData = updatedData.sort((a, b) => {
                    if (a.name < b.name) {
                      return -1;
                    }
                    if (a.name > b.name) {
                      return 1;
                    }
                    return 0;
                  });
                  setProductDropDownListData(newupdatedData)
            }
            else {
                dispatch(setApiError())
                setProductDropDownListData([])
            }
            setIsLoading(false)
        } catch (error) {
            setProductDropDownListData([])
            console.error(error)
            catchErrorHandler(error, dispatch(setApiError()));
            setIsLoading(false)
        }
    };

    const eventNameOptionList = async () => {
        setIsLoading(true)
        try {
            const response = await commonService.eventNameOptionList();
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                const updatedData = res?.data.map(item => ({
                    ...item,
                    value: item?.name,
                    name: item?.displayName
                }));
                let newupdatedData = updatedData.sort((a, b) => {
                    if (a.name < b.name) {
                      return -1;
                    }
                    if (a.name > b.name) {
                      return 1;
                    }
                    return 0;
                  });
                  setEventNameDropDownListData(newupdatedData)
            }
            else {
                dispatch(setApiError())
                setEventNameDropDownListData([])
            }
            setIsLoading(false)
        } catch (error) {
            setEventNameDropDownListData([])
            console.error(error)
            catchErrorHandler(error, dispatch(setApiError()));
            setIsLoading(false)
        }
    };

    const livelihoodNameOptionList = async () => {
        setIsLoading(true)
        try {
            const response = await commonService.livelihoodNameOptionList();
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                const updatedData = res?.data.map(item => ({
                    ...item,
                    value: item?.name,
                    name: item?.displayName
                }));
                let newupdatedData = updatedData.sort((a, b) => {
                    if (a.name < b.name) {
                      return -1;
                    }
                    if (a.name > b.name) {
                      return 1;
                    }
                    return 0;
                  });
                  setLivelihoodNameDropDownListData(newupdatedData)
            }
            else {
                dispatch(setApiError())
                setLivelihoodNameDropDownListData([])
            }
            setIsLoading(false)
        } catch (error) {
            setLivelihoodNameDropDownListData([])
            console.error(error)
            catchErrorHandler(error, dispatch(setApiError()));
            setIsLoading(false)
        }
    };

    useEffect(() => {
        eventTypeOptionList()
        assetTypeOptionList()
        productTypeOptionList()
        eventNameOptionList()
        livelihoodNameOptionList()

        if (isEdit) {
            const AssetNames = editData?.assets?.map(asset => asset.displayName);
            const ProductNames = editData?.products?.map(asset => asset.displayName);
            const EventName = editData?.events?.map(asset => asset.name);
            const EventType = editData?.events?.map(asset => asset.displayName);
            setAssetTypes(AssetNames)
            setProductTypes(ProductNames)
            setEventName(EventName)
            setEventType(EventType)
            setLivelihoodName(editData?.livelihoodName)
            setLivelihoodDisplayName(editData?.displayName)
            setImage(editData?.image)
            setImageName(editData?.image)
            setIsdefaultImg(editData?.image)
            setLivelihoodId(editData?.id)
            const newEvents = []
            EventName?.map((event, index) => {
                newEvents.push({ name: event, type: EventType[index] })
            })
            setEvents(newEvents);
        }
    }, [])

    const getDisplayName = (arr, name) =>{
        const item = arr?.find(obj => obj.value === name);
        return item ? item.displayName : null;
        }

    const handleAddAssetType = () => {
        const newType = getDisplayName(assetDropDownListData, assetType)
        setAssetTypes([...assetTypes, newType]);
        setAssetType('');
    };

    const handleAddProductType = () => {
        const newType = getDisplayName(ProductDropDownListData, productType)
        setProductTypes([...productTypes, newType]);
        setProductType('');
    };

    const getDisplayNameByValue = (value) => {
        const item = eventListData.find(obj => obj.value === value);
        return item ? item.displayName : null;
    }

    const handleAddEvent = () => {
        let newType = getDisplayNameByValue(selectEventType)
        setEvents([...events, { name: getDisplayName(eventNameDropDownListData, typeEventName), type: newType }]);
        setEventName([...eventName, typeEventName])
        setEventType([...eventType, selectEventType])
        setTypeEventName('');
        setSelectEventType('')
    };

    const handleDeleteAssetType = (index) => {
        setAssetTypes(assetTypes.filter((_, i) => i !== index));
    };

    const handleDeleteProductType = (index) => {
        setProductTypes(productTypes.filter((_, i) => i !== index));
    };

    const handleDeleteEvent = (index) => {
        setEventName(eventName.filter((_, i) => i !== index))
        setEventType(eventType.filter((_, i) => i !== index))
        setEvents(events.filter((_, i) => i !== index))
    };

    const handleIconUpload = () => {
        dispatch(openModal(MODALS.CREATE_LIVELIHOOD_ICON_UPLOAD_MODAL,
            {
                parentClass: "livelihood-icon-upload center-modal confirmation-modal",
                // confirmAction: confirmAction,
                modalTitle: 'Livelihood Icon',
                setImage: setImage,
                setImageName: setImageName,
                setIsdefaultImg: setIsdefaultImg,
            }))
    }

    const livelihoodSaveUpdate = async (payload) => {
        setIsLoading(true)
        try {
            const response = await commonService.livelihoodSaveUpdate(payload);
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                setToastMessage(res?.message)
            }
            else {
                dispatch(setApiError())
                setToastMessage('')
            }
            setIsLoading(false)
        } catch (error) {
            setToastMessage('')
            console.error(error)
            catchErrorHandler(error, dispatch(setApiError()));
            setIsLoading(false)
        }
    };

    const handleCreateUpdateLivelihood = () => {
        const getNameAndType = (arr, name) =>{
            const item = arr?.find(obj => obj.name === name);
            return item ? item.value : null;
            }
        const assetsData = assetTypes.map(name => ({
            name: getNameAndType(assetDropDownListData, name),
            status: 1,
            type: getNameAndType(assetDropDownListData, name),
            displayName: name,
        }));
        const productTypesData = productTypes.map(name => ({
            name: getNameAndType(ProductDropDownListData, name),
            status: 1,
            type: getNameAndType(ProductDropDownListData, name),
            displayName: name,
        }));
        const eventsData = eventName.map((name, index) => ({
            name: name,
            status: 1,
            type: eventType[index],
            displayName: getDisplayName(eventNameDropDownListData, name),
        }));
        const updatedEvents = eventsData.map(event => {
            const matchingData = eventListData.find(d => d.value === event.type);
            return {
                ...event,
                eventsTypeId: matchingData ? matchingData.id : null
            };
        });
        
        const segments = imageName.split('/');
        const imgName = segments.filter(segment => segment.includes('.png')).pop();
        
        let CreateData = {
            "name": livelihoodDisplayName,
            "type": livelihoodName,
            "displayName": livelihoodDisplayName,
            "status": 1,
            "image": imgName,
            "assets": assetsData,
            "products": productTypesData,
            "events": updatedEvents,
            "eventsCounts": eventsData.length
        }

        const assetMap = assetsData?.reduce((map, item) => {
            map[item.name] = item;
            return map;
        }, {});
  
        const updatedAsset = [...assetsData];

        editData?.assets?.forEach((editItem) => {
            if (!assetMap[editItem.name]) {
                updatedAsset.push({
                ...editItem,
                status: 0
            });
        }});

        // const newProductsMap = new Map(productTypesData?.map(na => [na.name, na]));
        // const updatedProducts = editData?.products?.map(a => {
        //     if (newProductsMap?.has(a.name)) {
        //         return {
        //             ...a,
        //             status: newProductsMap?.get(a.name).status
        //         };
        //     }
        //     return {
        //         id: a.id,
        //         name: a.name,
        //         status: 0
        //     };
        // });

        // productTypesData?.forEach(na => {
        //     if (!editData?.products?.some(a => a.name === na.name)) {
        //         updatedProducts?.push(na);
        //     }
        // });
        const productMap = productTypesData?.reduce((map, item) => {
            map[item.name] = item;
            return map;
        }, {});
  
        const updatedProduct = [...productTypesData];

        editData?.products?.forEach((editItem) => {
            if (!productMap[editItem.name]) {
                updatedProduct.push({
                ...editItem,
                status: 0
            });
        }});



        const newEventsMap = new Map(updatedEvents?.map(na => [na.name, na]));
        const updatedNewEvents = editData?.events?.map(a => {
            if (newEventsMap?.has(a.name)) {
                return {
                    ...a,
                    status: newEventsMap?.get(a.name).status
                };
            }
            return {
                id: a.id,
                name: a.name,
                status: 0,
                type: a.type,
            };
        });

        updatedEvents?.forEach(na => {
            if (!editData?.events?.some(a => a.name === na.name)) {
                updatedNewEvents?.push(na);
            }
        });

        let updateData = {
            "id": livelihoodId,
            "name": livelihoodDisplayName,
            "type": livelihoodName,
            "displayName": livelihoodDisplayName,
            // "status": 1,
            "image": imgName,
            "assets": updatedAsset,
            "products": updatedProduct,
            "events": updatedNewEvents,
            "eventsCounts": eventsData.length
        }

        livelihoodSaveUpdate(isEdit? updateData : CreateData)
        handleCreateLivelihoodPage(false)
    }

    const isButtonDisabled = !(
        livelihoodName && 
        livelihoodDisplayName &&
        imageName &&
        events?.length > 0 &&
        (assetTypes?.length > 0 || productTypes?.length > 0)
    );

    return (
        <div className='create-livelihood-container'>
            <div className='create-livelihood-body-sec'>
                <div className='create-livelihood-left-sec'>
                    <div className='create-livelihood-header-sec'>
                        <img
                            className='create-livelihood-back'
                            src={BlueLeftArrow}
                            onClick={() => handleCreateLivelihoodPage(false)}
                            height={21}
                        />
                        <span>
                           {isEdit ? "Edit" : "Create"} Livelihood
                        </span>
                    </div>
                    <div className='create-livelihood-input-outer'>
                        <div className='create-livelihood-input-sec'>
                            <label>Livelihood name <span>*</span></label>
                            <div className='input-group'>
                                <div className='livelihood-select-dropdown'>
                                   <CustomDropdown
                                            // showSearchBar={false}
                                            placeHolder="Select"
                                            onChange={(value) => setLivelihoodName(value)}
                                            options={livelihoodNameDropDownListData}
                                            value={livelihoodName} />
                                            </div>
                                <label className='livelihood-icon-upload-label' htmlFor="image-upload">
                                    <div className='livelihood-add' onClick={handleIconUpload}

                                    >

                                        {image ? '+' : '+ Icon'}

                                        {image && (isdefaultImg ?
                                        <SuperAdminImageWithoutToken imgUrl={isdefaultImg} placeholder={dummyImg} isPreview={false} alt='Livelihood img' className='livelihood-icon-img' />
                                        :
                                         <div style={{
                                            backgroundImage: image ? `url(${image})` : '',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            cursor: 'pointer',
                                            // display: 'inline-block',
                                            textAlign: 'center',
                                            // lineHeight: '150px',
                                            color: image ? 'transparent' : '#555',
                                            width: '20px',
                                            height: '20px',
                                            marginLeft: '5px'
                                        }}></div>)
                                        }
                                    </div>
                                </label>
                            </div>
                        </div>

                        <div className='create-livelihood-input-sec'>
                            <label>Display name(livelihood) <span>*</span></label>
                            <div className='input-group'>
                                <input
                                    className='livelihood-input'
                                    type="text"
                                    value={livelihoodDisplayName}
                                    onChange={(e) => setLivelihoodDisplayName(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='create-livelihood-input-sec'>
                            <label>Asset Type</label>
                            <div className='input-group'>
                                <div className='livelihood-select-dropdown'>
                                   <CustomDropdown
                                            // showSearchBar={false}
                                            placeHolder="Select"
                                            onChange={(value) => setAssetType(value)}
                                            options={assetDropDownListData}
                                            value={assetType} />
                                            </div>
                                <button disabled={assetType.length <= 0} className='livelihood-add' onClick={handleAddAssetType}>+ Add</button>
                            </div>
                        </div>

                        <div className='create-livelihood-input-sec'>
                            <label>Product Type</label>
                            <div className='input-group'>
                               
                            <div className='livelihood-select-dropdown'>
                                   <CustomDropdown
                                            // showSearchBar={false}
                                            placeHolder="Select"
                                            onChange={(value) => setProductType(value)}
                                            options={ProductDropDownListData}
                                            value={productType} />
                                            </div>
                                <button disabled={productType.length <= 0} className='livelihood-add' onClick={handleAddProductType}>+ Add</button>
                            </div>
                        </div>

                        <span className='create-livelihood-event-sec-lebel'>Event <span>*</span></span>
                        <div className='create-livelihood-input-sec'>
                            <div className='input-group'>
                                <div className='livelihood-event-input-outer'>
                                    <span className='livelihood-event-lebel'>Name</span>
                                    <div className='livelihood-select mr5'>
                                        <CustomDropdown
                                            // showSearchBar={false}
                                            placeHolder="Select"
                                            onChange={(value) => setTypeEventName(value)}
                                            options={eventNameDropDownListData}
                                            value={typeEventName} /></div>
                                </div>
                                <div className='livelihood-event-select-outer'>
                                    <span className='livelihood-event-lebel'>Type</span>
                                    <div className='livelihood-select'>
                                        <CustomDropdown
                                            // showSearchBar={false}
                                            placeHolder="Select"
                                            onChange={(value) => setSelectEventType(value)}
                                            options={eventListData}
                                            value={selectEventType} /></div>
                                </div>
                                <button disabled={selectEventType.length === 0 || typeEventName.length === 0} className='livelihood-add livelihood-event-add' onClick={handleAddEvent}>+ Add</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='create-livelihood-right-sec'>
                    {assetTypes.length === 0 && productTypes.length === 0 && events.length === 0 ?
                        <div className='no-livelihood-data'>
                            No items added
                        </div> :
                        <>
                            <div className='create-livelihood-selected-item'>
                                {assetTypes.length > 0 && <h3>Asset Types</h3>}
                                <div className='create-livelihood-selected-value-wrapper'>
                                    {assetTypes.map((type, index) => (
                                        <div className='livelihood-added-value' key={index}>
                                            {type}
                                            <img src={DeleteIcon} height={25} onClick={() => handleDeleteAssetType(index)} />
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className='create-livelihood-selected-item'>
                                {productTypes.length > 0 && <h3>Product Types</h3>}
                                <div className='create-livelihood-selected-value-wrapper'>
                                    {productTypes.map((type, index) => (
                                        <div className='livelihood-added-value' key={index}>
                                            {type}
                                            <img src={DeleteIcon} height={25} onClick={() => handleDeleteProductType(index)} />
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className='create-livelihood-selected-item'>
                                {eventName?.length > 0 && <h3>Events</h3>}
                                <div className='create-livelihood-selected-value-wrapper'>
                                    {events?.map((event, index) => (
                                        <div className='livelihood-added-value' key={index}>
                                            {event?.name} - {event?.type}
                                            <img src={DeleteIcon} height={25} onClick={() => handleDeleteEvent(index)} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>}
                </div>
            </div>
            <div className='create-livelihood-button-sec'>
                <button className='secondary-btn livelihood-cancel' onClick={() => handleCreateLivelihoodPage(false)}> Cancel</button>
                <button disabled={isButtonDisabled} className='primary-btn livelihood-create-save' onClick={() => handleCreateUpdateLivelihood()}> {isEdit ? "Save" : "Create"}</button>
            </div>
            <CustomModal />
        </div>
    )
}

export default CreateLivelihood