import React, { useState } from 'react'
import Header from '../../components/Common/Header'
import './style.scss'
import SearchBar from '../../components/SearchBar'
import Table from '../../components/Common/Table';
import TableColumns from './TableColumns';

const DownloadsFlow = () => {

    const [searchData, setSearchData] = useState('');
    const [searchedDataList, setSearchedDataList] = useState([]);

    const tableDummyData = [
        {fileName: "file1",
         dateAndTime: "12/02/24",
         recordsCount: 21,
         status: "Success",
        },
        {fileName: "file2",
            dateAndTime: "12/02/24",
            recordsCount: 21,
            status: "Success",
           },
           {fileName: "file3",
            dateAndTime: "12/02/24",
            recordsCount: 21,
            status: "Failed",
           },
           {fileName: "file4",
            dateAndTime: "12/02/24",
            recordsCount: 21,
            status: "Failed",
           },
           {fileName: "file1",
            dateAndTime: "12/02/24",
            recordsCount: 21,
            status: "Success",
           },
           {fileName: "file2",
               dateAndTime: "12/02/24",
               recordsCount: 21,
               status: "Success",
              },
              {fileName: "file3",
               dateAndTime: "12/02/24",
               recordsCount: 21,
               status: "Success",
              },
              {fileName: "file4",
               dateAndTime: "12/02/24",
               recordsCount: 21,
               status: "Failed",
              },
              {fileName: "file1",
                dateAndTime: "12/02/24",
                recordsCount: 21,
                status: "Success",
               },
               {fileName: "file2",
                   dateAndTime: "12/02/24",
                   recordsCount: 21,
                   status: "Success",
                  },
                  {fileName: "file3",
                   dateAndTime: "12/02/24",
                   recordsCount: 21,
                   status: "Success",
                  },
                  {fileName: "file4",
                   dateAndTime: "12/02/24",
                   recordsCount: 21,
                   status: "Success",
                  },
                  {fileName: "file1",
                    dateAndTime: "12/02/24",
                    recordsCount: 21,
                    status: "Success",
                   },
                   {fileName: "file2",
                       dateAndTime: "12/02/24",
                       recordsCount: 21,
                       status: "Success",
                      },
                      {fileName: "file3",
                       dateAndTime: "12/02/24",
                       recordsCount: 21,
                       status: "Success",
                      },
                      {fileName: "file4",
                       dateAndTime: "12/02/24",
                       recordsCount: 21,
                       status: "Success",
                      },
    ]

    const handleSearchFilter = (value) => {
        let filteredList
        setSearchData(value)
        filteredList = tableDummyData.filter(x => x.fileName.toLowerCase().includes(value.toLowerCase()))
        setSearchedDataList(filteredList)
    }

    return (
        <div className="main-page download-flow fixed-header">
            <Header />
            <div className="main-section downloads-flow-main">
                <div className='download-flow-container'>
                    <div className='download-flow-search-outer'>
                    <SearchBar
                                handleOnChange={(e) => handleSearchFilter(e.target.value)}
                                placeholder='Search file name'
                                value={searchData}
                            />
                    </div>
                    <div className='download-flow-body'>
                    <Table className='download-flow-table pending-table' 
                    columns={TableColumns()}
                    data={searchData ? searchedDataList : tableDummyData} 
                    checkbox={false} 
                    selectAll={false} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DownloadsFlow