import React, { useEffect, useState } from 'react'
import SearchBar from '../../../components/SearchBar'
import './style.scss'
import Table from '../../../components/Common/Table'
import TableColumns from './TableColumns'
import FileIcon from '../../../assets/images/file-text.svg';
import { openModal } from '../../../storeManager/modal/actions'
import { MODALS } from '../../../storeManager/modal/constants'
import { useDispatch } from 'react-redux'
import CustomModal from '../../../components/Common/Modal'
import { commonService } from '../../../services/common'
import { catchErrorHandler } from '../../../services/commonUtils'
import { setApiError } from '../../../storeManager/common/actions'
import GlobalConfig from '../../../config/globalconfig';
import PreLoader from '../../../components/Common/Preloader'
import ToastMsg from '../../../components/Common/ToastMsg'

const Livelihood = ({ handleCreateLivelihoodPage, toastMessage, setToastMessage, fetchTabData }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [listData, setListData] = useState([]);
    const dispatch = useDispatch();

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const livelihoodDelete = async ( livelihoodId ) => {
        setIsLoading(true)
        const payload = {
            "referenceType": "LIVELIHOOD",
            "livelihoodId": livelihoodId
        }
        try {
            const response = await commonService.deleteLivelihood(payload);
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                setToastMessage(res?.data?.message);
                    dispatch(openModal(MODALS.LIVELIHOOD_DELETE_MODAL,
                        {
                            parentClass: "super-admin-delete-info center-modal confirmation-modal livelihood-delete",
                            modalTitle: `Livelihood ID: ${livelihoodId}`,
                            status : res?.data?.status,
                            msg : res?.data?.message,
                            type: 'add',
                            referenceId: livelihoodId,
                        }))
            }
            else {
                dispatch(setApiError())
                setToastMessage('')
            }
            setIsLoading(false)
        } catch (error) {
            setToastMessage('')
            console.error(error)
            catchErrorHandler(error, dispatch(setApiError()));
            setIsLoading(false)
        }
    };

    const livelihoodDataList = async () => {
        setIsLoading(true)
        try {
            const response = await commonService.livelihoodDataList(0, 1);
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                setListData(res?.data)
            }
            else {
                dispatch(setApiError())
                setListData([])
            }
            setIsLoading(false)
        } catch (error) {
            setListData([])
            console.error(error)
            catchErrorHandler(error, dispatch(setApiError()));
            setIsLoading(false)
        }
    };

    const handleDelete = (livelihoodId, livelihoodName) => {
        dispatch(openModal(MODALS.SUPER_ADMIN_DELETE_INFO_MODAL,
            {
                parentClass: "super-admin-delete-info center-modal confirmation-modal",
                btnText: "Delete",
                confirmAction: livelihoodDelete,
                setToastMessage: setToastMessage,
                isLivelihood: true,
                id: livelihoodId,
                status: "livelihood",
                btnClass: "livelihood-delete",
                modalTitle: 'Are you sure to delete Livelihood ?',
                message: `Deleting Livelihood: ${livelihoodName}, which consists of all the didis, missions, grants, etc will be removed.`
            }))
    }

    useEffect(()=>{
            setToastMessage('')
    },[])
    
    useEffect(()=>{
        livelihoodDataList()
        fetchTabData()
    },[toastMessage])

    const filteredData = listData.filter(item => {
        const term = searchQuery.toLowerCase();
        return item.name.toLowerCase().includes(term) || item.id.toString().includes(term);
    });

    if (isLoading) {
        return <PreLoader />;
      }

    return (
        <div className='outer-livelihood'>
            <div className='livelihood-upper-section'>
                <SearchBar className="livelihood-search-bar" placeholder='Search livelihood name, ID' value={searchQuery} onChange={handleSearch} />
                <div className='livelihood-upper-section-right'>
                    <button className='primary-btn livelihood-create-btn' onClick={() => handleCreateLivelihoodPage(true, false)}>
                        + Create livelihood
                    </button>
                </div>
            </div>
            <div className='livelihood-lower-section'>
                {filteredData.length > 0 ? (
                    <Table className='livelihood-table pending-table' columns={TableColumns({ handleCreateLivelihoodPage, handleDelete })} data={filteredData} checkbox={false} selectAll={false} />
                ) : (
                    <div className='livelihood-noData-sec'>
                        <img src={FileIcon} />
                        <span>No livelihood created</span>
                    </div>
                )}
            </div>
            <CustomModal />
            {toastMessage && !toastMessage.toLowerCase().includes('delete') ? <ToastMsg otpMsg={toastMessage} success={true} /> : ''}
        </div>
    )
}

export default Livelihood