export const sectionToggleOptions = (type, role) => [
  {
    value: "Chart View",
  },
  {
    value: "Source Data",
  },
];



export const getboxData = (boxValues, imgObj, role) => {
  const boxData = [
  {
    imgName: imgObj?.mapLayer,
    name: "No. of Villages",
    values:
      role && ["National", "State", "Block", "District"].includes(role)
        ? boxValues?.numberofVillages
        : "-",
  },
  {
    imgName: imgObj?.vo,
    name: "No. Of VOs",
    values: boxValues?.numberOfVo,
  },
  {
    imgName: imgObj?.users,
    name: "No. Of UPCMs",
    values: boxValues?.upcmsCount,
  },
  {
    imgName: imgObj?.usericon,
    name: "No. Of BPM approved Didis",
    values: boxValues?.bpmApprovedDidisCount,
  },
  {
    imgName: imgObj?.usericon,
    name: "Drop-Out",
    values: boxValues?.didiDropOutCount
  },
  {
    imgName: imgObj?.usericon,
    name: "No. of Didis covered (BPM approved didis - dropped)",
    values: boxValues?.coveredDidis
  },
]

if (role === "District") {
  boxData.unshift({
    imgName: imgObj?.layer,
    name: "No. of Blocks",
    values:
      role && ["National", "State", "District"].includes(role)
        ? boxValues?.numberOfBlocks
        : "-",
  });
} else if (role !== "Block") {
  boxData.unshift(
    {
      imgName: imgObj?.layer,
      name: "No. of Districts",
      values:
        role && ["National", "State"].includes(role)
          ? boxValues?.numberOfDistricts
          : "-",
    },
    {
      imgName: imgObj?.layer,
      name: "No. of Blocks",
      values:
        role && ["National", "State", "District"].includes(role)
          ? boxValues?.numberOfBlocks
          : "-",
    }
  );
}

return boxData;
}

export const getRoles = {
  National: "",
  State: "stateId",
  District: "districtId",
  Block: "blockId",
  Village: "villageId",
};

export const getResponse = {
  National: "states",
  State: "districts",
  District: "blocks",
  Block: "villages",
};

export const getHeading = (type) => {
  let obj = {
    National: "States",
    State: "Districts",
    District: "Blocks",
    Block: "Villages",
  };

  return obj[type];
};

export const colorConfig = {
  POOR: "green",
  MEDIUM: "yellow",
  RICH: "red",
};


export const graphTransformer = ({ data, toggleType = true, activity }) => {
  let graphData = [];
  let graphDropDown = [];

  const { doer, subject, id } = activity

  if (data) {
    data?.map((item) => {
      let newData = {}
      newData = {
        name: item.doerName,
        uv: toggleType ? item?.completedCount : Math.trunc(item?.taskCompletionPercentage),
        pv: 0,
        amt: item?.subjectCount,
        pct: Math.trunc(item?.taskCompletionPercentage),
        calculatedTotal: toggleType ? 0 : 100 - item?.taskCompletionPercentage- item?.taskInProgressPercentage - item?.taskNotStartedPercentage- item?.taskNotAvailablePercentage,
        count: item?.subjectCount,
        notStartedCount: item?.notStartedCount,
        inProgressCount: item?.inProgressCount,
        completedCount: item?.completedCount,
        notAvailableCount: item?.notAvailableCount,
        pctTotal: Math.trunc(item?.taskCompletionPercentage),
        pctInprogress: item?.taskInProgressPercentage,
        pctNotStarted: item?.taskNotStartedPercentage,
        pctNotAvailable: item?.taskNotAvailablePercentage,
      }
      graphData.push(newData)
    })
  }
  return { graphData, graphDropDown };
};


export const summaryTransformer = ({ activity, data }) => {


  let heading = getHeaders(activity);
  let summaryValues = [];
  // const checkEmptyValues = graphTransformer({data,toggleType: false, activity})?.graphData?.every(item => item.uv === 0);

  summaryValues = Object.keys(data)?.length > 0 ? graphTransformer({ data, toggleType: false, activity })?.graphData?.map(summaryvalue => (
    <tr>
      <td>{summaryvalue.name}</td>
      <td>{summaryvalue.count}</td>
      <td>{summaryvalue.notStartedCount}</td>
      <td>{summaryvalue.inProgressCount}</td>
      <td>{summaryvalue.completedCount}</td>
      <td>{summaryvalue.notAvailableCount}</td>
      <td>{summaryvalue.pct}</td>
    </tr>
  )) : []


  return {
    heading,
    summaryValues,
  };
};

export const getSourceDataMapping = (activity, data, mapType = '') => {
  let heading = [];
  let sourceValues = [];

  sourceValues = Array.isArray(data) && (data?.length > 10 ? data?.slice(0, 10) : data)?.map((sources, ind) => (
    <tr>
      <td>{ind + 1}</td>
      <td>{sources?.doerId || '-'}</td>
      <td>{sources?.doerName?.replace('_', ' ') || '-'}</td>
      <td>{sources?.subjectId || '-'}</td>
      <td>{sources?.subjectName?.replace('_', ' ') || '-'}</td>
      <td>{sources?.taskStatus?.replace('_', ' ') || '-'}</td>
      <td>{sources?.villageName?.replace('_', ' ') || '-'}</td>
      <td>{sources?.blockName?.replace('_', ' ') || '-'}</td>
      <td>{sources?.districtName?.replace('_', ' ') || '-'}</td>
      <td>{sources?.stateName?.replace('_', ' ') || '-'}</td>
    </tr>
  )) || []


  heading = getSourceHeaders(activity);

  return {
    heading,
    sourceValues,
  };
};

const getSourceHeaders = (activity) => {
  const { doer, subject, id } = activity

  let heading = [
    "S.No.",
    `${doer} ID`,
    `${doer} name`,
    `${subject} ID`,
    `${subject} name`,
    "Task Status",
    "Village",
    "Block",
    "District",
    "State",
  ];
  return heading;
}

export const getHeaders = (activity) => {

  const { doer, subject, id } = activity

  let heading = [
    `${doer}`,
    `Total ${subject} count`,
    `${subject}'s Not started`,
    `${subject}'s In progress`,
    `${subject}'s Completed`,
    `${subject}'s Not Available`,
    '% of task completion'
  ];
  return heading;
}


export const getCsvDetails = (type, sourceData, mapType = '') => {
  // Headers for each column
  let headers = [getHeaders(type).toString()];

  // // Convert users data to a csv
  let newData = sourceData || [];

  let usersCsv = newData?.reduce((acc, user) => {

    let userArr = []


    acc.push(userArr.join(','))

    return acc
  }, [])

  return { headers, usersCsv };
  //keep headers array

}


export const myArr = () => {
  var my_array = [];

  for (var i = 10; i <= 500; i++) {
    my_array.push(i);
  }

  return my_array;
}

