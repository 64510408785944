import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";

// import areaChartImg from '../../assets/images/AreaChart.svg'
// import donutChartImg from '../../assets/images/DonutChart.svg'
import HorizontalBarChartImg from '../../assets/images/HorizontalBar.svg'
import HistogramBar from '../../assets/images/HistogramBar.svg'
import HistogramBarGrey from '../../assets/images/HistogramBarGrey.svg'
import HorizontalBarGrey from '../../assets/images/HorizontalBarGrey.svg'
import mapImg from '../../assets/images/IndiaMap.svg'
import mapImgGrey from '../../assets/images/IndiaMapGrey.svg'
import stackedChartImg from '../../assets/images/StackedBar.svg'
import stackedChartGrey from '../../assets/images/StackedBarGrey.svg'
import waterfallChartImg from '../../assets/images/WaterfallChart.svg'
import waterfallChartGrey from '../../assets/images/WaterfallChartGrey.svg'
import barChartImg from '../../assets/images/BarChartImg.svg'
import barChartGrey from '../../assets/images/BarChartImgGrey.svg'
import downloadIconGrey from '../../assets/images/download-iconGrey.svg'
import downloadIcon from '../../assets/images/download-iconBlue.svg'
import SearchBar from "../SearchBar";
// import PatReasons from "../../assets/images/reason.png"
import { debounce } from "../../services/commonUtils";
import { useDispatch } from "react-redux";
import { showLoaderAction, leftArrowAction, rightArrowAction, setActiveGeoFilters, setCurrentGeoRef, setRoleValue } from "../../storeManager/common/actions";
import arrow from "../../assets/images/left-arrow.png";
import arrowActive from "../../assets/images/right-arroww.png";
import progressBar from '../../assets/images/progressBar.png'
import progressBarActive from '../../assets/images/progressBarActive.png'
import './style.scss'


const WidgetSlider = forwardRef(({
  activityList,
  setWidgetChange,
  setSlide,
  setStateName,
  setDistrictName,
  setShowExcelCta,
  widgetChange,
  setSectionToggle,
  setSummary,
  setSourceData,
  setQuestion,
  type,
  roleName,
  setToggleType,
  setIsError,
  setSummaryRole,
  widgetType,
  // setshowLoader,
  widgetIsCollapsed,
  setWidgetIsCollapsed
}, ref) => {
  // const sliderRef = useRef(null);
  const dispatch = useDispatch();
  // const { activeGeoFilter } = useSelector((state) => state.commonReducer);
  const activeGeoFilter = {
    state: {},
    district: {},
    block: {},
    village: {}
  }
  const { roleName: userRole, referenceId: refId } =
    JSON.parse(localStorage.getItem("userAuth")) || "";
  // const slickSliderClass = document.querySelector('.slick-track');


  // const [widgetArray, setWidgetArray] = useState()

  const [inputValue, setInputValue] = useState('')
  const [currentIndex, setCurrentIndex] = useState(0)
  const [scrollPos, setScrollPos] = useState(0)
  const [offsetHeight, setOffsetHeight] = useState(0)
  const [filteredList, setFilteredList] = useState([])

  // const settings = {
  //   //   dots: true,
  //   infinite: false,
  //   slidesToShow: 1,
  //   slidesToScroll: 2,
  //   vertical: true,
  //   //   verticalSwiping: true,
  //   beforeChange: function (currentSlide, nextSlide) {
  //     // console.log("before change", currentSlide, nextSlide);
  //   },
  //   afterChange: function (currentSlide) {
  //     // console.log("after change", currentSlide);
  //   },
  // };

  const geoChartObj={
    id: 1,
      name: "Geographic view",
      img: widgetChange?.id === 1 ? mapImg : mapImgGrey,
  }
  let widgetArray = [];
  if (widgetType === "baseline"){
    widgetArray = [
      // {
      //   id: 1,
      //   name: "Geographic view",
      //   img: widgetChange.id === 1 ? mapImg : mapImgGrey,
      //   // content: <AreaSelector slide={slide} />,
      // },
      {
        id: 1,
        name: "Completion Summary",
        img: widgetChange.id === 1 ? progressBarActive : progressBar,
        // content: <LineChartComponent slide={slide} />,
      },
      {
        id: 2,
        name: "Overall progress of Baseline Survey",
        img: widgetChange.id === 2 ? HorizontalBarChartImg : HorizontalBarGrey,
        // content: <LineChartComponent slide={slide} />,
      },
      {
        id: 9,
        name: "Overall progress of Hamlet Survey",
        img: widgetChange.id === 9 ? HorizontalBarChartImg : HorizontalBarGrey,
        // content: <LineChartComponent slide={slide} />,
      },
      {
        id: 10,
        name: "Baseline & Hamlet Survey",
        img: widgetChange.id === 10 ? downloadIcon : downloadIconGrey,
        // content: <LineChartComponent slide={slide} />,
      },
      // {
      //   id: 3,
      //   name: "Household Distribution",
      //   img: widgetChange.id === 3 ? barChartImg : barChartGrey,
      //   // content: <LineChartComponent slide={slide} />,
      // },
      // {
      //   id: 4,
      //   name: "Food Security Distribution",
      //   img: widgetChange.id === 4 ? barChartImg : barChartGrey,
      //   // content: <LineChartComponent slide={slide} />,
      // },
      // {
      //   id: 5,
      //   name: "Social Inclusion Distribution",
      //   img: widgetChange.id === 5 ? barChartImg : barChartGrey,
      //   // content: <LineChartComponent slide={slide} />,
      // },
      // {
      //   id: 6,
      //   name: "Financial inclusion distribution",
      //   img: widgetChange.id === 6 ? barChartImg : barChartGrey,
      //   // content: <LineChartComponent slide={slide} />,
      // },
      // {
      //   id: 7,
      //   name: "Distribution of Entitlements",
      //   img: widgetChange.id === 7 ? barChartImg : barChartGrey,
      //   // content: <LineChartComponent slide={slide} />,
      // },
      // {
      //   id: 8,
      //   name: "Distribution of livelihood income",
      //   img: widgetChange.id === 8 ? barChartImg : barChartGrey,
      //   // content: <LineChartComponent slide={slide} />,
      // },
      {
        id: 11,
        name: "Baseline Survey Dashboard",
        img: widgetChange.id === 11 ? barChartImg : barChartGrey,
        // content: <LineChartComponent slide={slide} />,
      },{
        id: 12,
        name: "Hamlet Survey Dashboard",
        img: widgetChange.id === 12 ? barChartImg : barChartGrey,
        // content: <LineChartComponent slide={slide} />,
      },
      // {
      //   id: 14,
      //   name: "Baseline & Hamlet Survey - Data View/Download",
      //   img: widgetChange.id === 14 ? barChartImg : barChartGrey,
      //   // content: <LineChartComponent slide={slide} />,
      // },
    ];
  }
  else if(widgetType === "selection"){
  widgetArray = [
    {
      id: 1,
      name: "Geographic view",
      img: widgetChange.id === 1 ? mapImg : mapImgGrey,
      // content: <AreaSelector slide={slide} />,
    },

    {
      id: 2,
      name: "Selection Process Summary",
      img: widgetChange.id === 2 ? waterfallChartImg : waterfallChartGrey,
      // content: <LineChartComponent slide={slide} />,
    },
    /*{
      id: 3,
      name: "Summary of Participatory Wealth Ranking (PWR)",
      img: widgetChange.id === 3 ? barChartImg : barChartGrey,
      // content: <BarChartComponent slide={slide} />,
    },
    */
    {
      id: 4,
      name: "Summary of PAT Survey",
      img: widgetChange.id === 4 ? waterfallChartImg : waterfallChartGrey,
      // content: <WaterFallChartComponent slide={slide} />,
    },
    /*
    {
      id: 5,
      name: "Summary of VO Endorsement",
      img: widgetChange.id === 5 ? waterfallChartImg : waterfallChartGrey,
      // content: <WaterFallChartComponent slide={slide} />,
    },
    {
      id: 6,
      name: "Summary of BPM Approval",
      img: widgetChange.id === 6 ? waterfallChartImg : waterfallChartGrey,
      // content: <WaterFallChartComponent slide={slide} />,
    },
    */
    {
      id: 7,
      name: "Overall progress of Selection Process",
      img: widgetChange.id === 7 ? HorizontalBarChartImg : HorizontalBarGrey,
      // content: <LineChartComponent />,
    },
    /*
    {
      id: 8,
      name: "Caste-wise summary of PWR",
      img: widgetChange.id === 8 ? stackedChartImg : stackedChartGrey,
      // content: <LineChartComponent />,
    },
    {
      id: 9,
      name: "Caste-wise summary of didis selected by stage of process",
      img: widgetChange.id === 9 ? barChartImg : barChartGrey,
      // content: <LineChartComponent />,
    },
    {
      id: 10,
      name: "Summary of scores of PAT selected",
      img: widgetChange.id === 10 ? barChartImg : barChartGrey,
      // content: <LineChartComponent />,
    },
    {
      id: 11,
      name: "Summary of scores of PAT rejected",
      img: widgetChange.id === 11 ? barChartImg : barChartGrey,
      // content: <LineChartComponent />,
    },
    {
      id: 12,
      name: "Caste-wise summary of VO Endorsement",
      img: widgetChange.id === 12 ? stackedChartImg : stackedChartGrey,
      // content: <LineChartComponent />,
    },
    {
      id: 13,
      name: "Summary of PAT exclusion reasons",
      img: widgetChange.id === 13 ? barChartImg : barChartGrey,
      // content: <LineChartComponent />,
    },
    {
      id: 14,
      name: "Summary of reasons of PAT selected",
      img: widgetChange.id === 14 ? barChartImg : barChartGrey,
      // content: <LineChartComponent />,
    }, {
      id: 15,
      name: "Summary of reasons of PAT rejected",
      img: widgetChange.id === 15 ? barChartImg : barChartGrey,
      // content: <LineChartComponent />,
    },*/
  ]
  } else if (widgetType === "grant"){
    widgetArray = [
      // {
      //   id: 1,
      //   name: "CSG - overall progress",
      //   img: widgetChange.id === 1 ? HorizontalBarChartImg : HorizontalBarGrey,
      //   // content: <AreaSelector slide={slide} />,
      // },
      {
        id: 2,
        name: "Overall progress of Receipt of Funds by VO",
        img: widgetChange.id === 2 ? HorizontalBarChartImg : HorizontalBarGrey,
        // content: <LineChartComponent slide={slide} />,
      },
      {
        id: 3,
        name: "Overall progress of CSG Disbursement to Didi",
        img: widgetChange.id === 3 ? HorizontalBarChartImg : HorizontalBarGrey,
        // content: <LineChartComponent slide={slide} />,
      },
    ];
  }  else if (widgetType === "livelihood"){
    widgetArray = [
      {
        id: 1,
        name: "Didi’s Income / Expense",
        img: widgetChange.id === 1 ? HistogramBar : HistogramBarGrey,
        // content: <AreaSelector slide={slide} />,
      },
      {
        id: 2,
        name: "Average Income / Expense / Asset value",
        img: widgetChange.id === 2 ? HistogramBar : HistogramBarGrey,
        // content: <LineChartComponent slide={slide} />,
      },
      {
        id: 3,
        name: "No Event Entries",
        img: widgetChange.id === 3 ? HorizontalBarChartImg : HorizontalBarGrey,
        // content: <LineChartComponent slide={slide} />,
      },
    ];
  } else if(widgetType === "generic"){
    widgetArray = activityList.map(_activity => {
      return {
        id: _activity.id,
        name: _activity.name,
        img: widgetChange?.id === _activity.id ? HorizontalBarChartImg : HorizontalBarGrey,
      }
    })
  }

  if (roleName === "Block" || roleName === "District"){
    if(widgetType === "selection"){
      widgetArray.shift();
      widgetArray.push(geoChartObj)
    }
  }
  useEffect(() => {
    const slideLength = newWidgetArray.length;
    if (currentIndex === 0)
      dispatch(leftArrowAction(false))
    else
      dispatch(leftArrowAction(true))
    if (currentIndex + 1 === slideLength)
      dispatch(rightArrowAction(false))
    else
      dispatch(rightArrowAction(true))

      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex])
  useImperativeHandle(ref, () => ({
    dashboardNext() {
      if ((currentIndex + 1) < newWidgetArray?.length) {
        setDashboardChange(newWidgetArray[currentIndex + 1])
        // sliderRef.current.slickGoTo(currentIndex + 1)
        setCurrentIndex(currentIndex + 1)
      }
    },
    dashboardPrev() {
      if ((currentIndex - 1) >= 0) {
        setDashboardChange(newWidgetArray[currentIndex - 1])
        // sliderRef.current.slickGoTo(currentIndex - 1)
        setCurrentIndex(currentIndex - 1)
      }
    }
  }));
  const refobj = refId?.map((i) => {
    return {
      value: i.name,
      id: i.id,
    };
  });
  const setDashboardChange = (widget) => {
    if (widgetChange.id !== widget.id) {
      // setshowLoader(true)
      dispatch(showLoaderAction(true));
      dispatch(setRoleValue({ roleName: roleName, roleId: refobj[0]?.id || '' }))
      switch (userRole) {
        case "State":
          dispatch(setActiveGeoFilters({ ...activeGeoFilter, state: { roleName: roleName, roleId: refobj[0]?.id, name: refobj[0]?.value } }))
          break;
        case "District":
          dispatch(setActiveGeoFilters({ ...activeGeoFilter, district: { roleName: roleName, roleId: refobj[0]?.id, name: refobj[0]?.value } }))
          break;
        case "Block":
          dispatch(setActiveGeoFilters({ ...activeGeoFilter, block: { roleName: roleName, roleId: refobj[0]?.id, name: refobj[0]?.value } }))
          break;
        default:
          dispatch(setActiveGeoFilters({
            state: {},
            district: {},
            block: {},
            village: {}
          }))
          break;
      }
      
      dispatch(setCurrentGeoRef({}))
      setSummaryRole(roleName);
      setIsError(false)
      setSlide(false);
      setToggleType(true);
      setShowExcelCta(false);
      setStateName("");
      setDistrictName("");
      setQuestion('');
      if(widgetType !== "livelihood") {
      setSectionToggle(widget.id === 1 ? roleName === "Block" ? "Summary View" : "Map View" : "Chart View");
      }
      setSummary([]);
      setSourceData([]);
    }
    if (widgetType === "generic") {
      let widgetData = activityList.find(_w => _w.id === widget.id)
      setWidgetChange({
        ...widget,
        ...widgetData
      })
    } else {
      setWidgetChange(widget);
    }
    
  };
  const handleWidgetChange = (value) => {
    setInputValue(value);
    const filteredWidgetsByName = widgetArray.filter(widgetObj => widgetObj.name.toLowerCase().includes(value.toLowerCase()));
    
      setFilteredList(filteredWidgetsByName)

  }

  // console.log(sliderRef.current, "sldoerrr");
  const newWidgetArray = inputValue !== '' ? filteredList : widgetArray;


  // useEffect(() => {

  //   if (inputValue === '' && slickSliderClass) {
  //     slickSliderClass.style.transform = "translate3d(0px, 0px, 0px)";
  //   }

  // }, [inputValue, slickSliderClass])

  const handleScroll = (e) => {
    setScrollPos(e.target.scrollTop)
    setOffsetHeight(e.target.offsetHeight)
  }
  const handleNav = (type) => {
    const listInnerHeight = document.getElementById('list-inner').offsetHeight;
    const list = document.getElementById('widget-list')
    if (type === 'next') {
      list.scrollTo({ top: scrollPos + 134, behavior: "smooth" })
      if ((scrollPos + 134) > (listInnerHeight - offsetHeight))
        setScrollPos(listInnerHeight - offsetHeight)
      else
        setScrollPos(scrollPos + 134)
    }
    if (type === 'prev') {
      list.scrollTo({ top: scrollPos - 134, behavior: "smooth" })
      if (scrollPos - 134 < 0)
        setScrollPos(0)
      else
        setScrollPos(scrollPos - 134)
    }
  }
  return (
    <div className={`outer-widget-section ${widgetIsCollapsed ? 'outer-widget-section-expanded' : 'outer-widget-section-collapsed'}`}>
    <div className="Widget-taugle-sec">
      <img className={`dropdown-icon ${widgetIsCollapsed ? 'icon-active' : ''}`} src={widgetIsCollapsed ? arrowActive : arrow} onClick={() => setWidgetIsCollapsed(!widgetIsCollapsed)} height={20} alt='arrow' />
      </div>
    <div className={`widget-section ${widgetIsCollapsed ? 'expanded' : 'collapsed'}`}>
      <SearchBar placeholder="Search" handleOnChange={debounce((e) => handleWidgetChange(e.target.value), 500)} />
      {
        newWidgetArray.length > 0 ? (
          <>
            <h2>Click on widgets to view data:</h2>

            {/* <Slider ref={sliderRef} {...settings}> */}
            <div id="widget-list" className="widget-list" onScroll={(e) => handleScroll(e)}>
              <ul id="list-inner">
                {newWidgetArray.map((widget, index) => (
                  <li
                    key={widget.id}
                    onClick={() => {
                      // sliderRef.current.slickGoTo(index);
                      setCurrentIndex(index)
                      setDashboardChange(widget);
                    }}
                    className={`widget-data ${widgetChange.id === widget.id ? "current" : ""}`}
                  >
                    {widgetChange.id === widget.id && <span className="active-widget" />}
                    <img
                      className={widget?.name?.replaceAll("&", "").replaceAll(" ", "")}
                      src={widget.img}
                      width={widget.name === "Geographic view" ? "79" : widget.id === 12 ? "55%" : "73%"}
                      alt="widget"
                    />
                    <p className="widget-txt">{widget.name}</p>
                  </li>
                ))}
              </ul>
            </div>
            <button type="button" className="slick-arrow slick-prev" onClick={() => handleNav('prev')}></button>
            <button type="button" className="slick-arrow slick-next" onClick={() => handleNav('next')}></button>
            {/* </Slider> */}
          </>
        ) : <h2 style={{ height: '72.5vh' }}>No Matched Options</h2>
      }

    </div>
    </div>
  );
});

export default WidgetSlider;
