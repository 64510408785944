import React, { useState, useEffect, useCallback } from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import './style.scss';
import CustomDropdown from '../../CustomDropdown';
import { commonService } from '../../../../services/common';
import GlobalConfig from '../../../../config/globalconfig';
import { useDispatch, useSelector } from 'react-redux';
import { setApiError, showLoaderAction } from '../../../../storeManager/common/actions';
import { catchErrorHandler, debounce, searchInArrByKey } from '../../../../services/commonUtils';
import SelectDidiCard from '../../SelectDidiCard';
import SearchBar from '../../../SearchBar';
import { openModal } from '../../../../storeManager/modal/actions';
import { MODALS } from '../../../../storeManager/modal/constants';
import Table from '../../Table';
import TableColumns from './columns';
import FallbackComp from '../../FallbackComp';



const DidiSelectionModal = ({ modalParameters: { blockId, callBackFn, activeUpcm }, closePopup }) => {

    const dispatch = useDispatch();
    const { isApiError } = useSelector(state => state.commonReducer)
    const [selectedDidis, setSelectedDidis] = useState([]);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [upcmSearch, setUpcmSearch] = useState("");
    const [didiList, setDidiList] = useState([])
    const [isConfirmation, setIsConfirmation] = useState(false);
    const [tableData, setTableData] = useState([])
    const [isConfirm, setIsConfirm] = useState(false)
    // const [tolaIds, setTolaIds] = useState([])

    useEffect(() => {
        fetchUnassignedDidis(blockId)
    }, [])
    const fetchUnassignedDidis = async (id, searchStr = "") => {
        dispatch(showLoaderAction(true));
        try {
            const response = await commonService.getUnassignedDidiOfBlock(id, searchStr);
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                setDidiList(res.data.beneficiaries)
            }
            else
                dispatch(setApiError())
            dispatch(showLoaderAction(false));

        } catch (error) {
            dispatch(showLoaderAction(false));
            console.error(error)
            catchErrorHandler(
                error, 
                dispatch(setApiError()),
                {
                    actionType: "SELECT",
                    actionStatusType:"ERROR"
                }
            );
        }
    };
    const handleUpcmUpdate = async () => {
        dispatch(showLoaderAction(true));
        const payload = {
            response: tableData,
            targetUpcmId: activeUpcm?.id
        }
        try {
            const response = await commonService.updateUpcmOnConfirm(payload);
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                // setSubmitSuccessful(true)
                setIsConfirm(false)
                // callBackFn()
                dispatch(openModal(MODALS.INFO_MODAL,
                    {
                        parentClass: "center-modal confirmation-modal",
                        info: /*${tableData?.length}*/ `${(new Set(tableData.map(item => item.tolaName))).size} Tolas are assigned successfully to ${activeUpcm?.upcmName}.`,
                        // modalTitle: '',
                        showActionBtn: false
                    }))
            }
            else
                dispatch(setApiError())
            dispatch(showLoaderAction(false));

        } catch (error) {
            dispatch(showLoaderAction(false));
            console.error(error)
            catchErrorHandler(
                error, 
                dispatch(setApiError()),
                {
                    actionType: "UPDATE",
                    actionStatusType:"ERROR"
                }
            );
        }
    }
    const updatedUpcm = async () => {
        dispatch(showLoaderAction(true));
        let payload = {
            "beneficiaryIds": selectedDidis,
            "sourceUpcmId": activeUpcm?.id,
            "action": "ASSIGN",
            "targetUpcmId": ""
        }

        try {
            const response = await commonService.updateUpcmDidis(payload);
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS && Array.isArray(res?.data)) {
                callBackFn()
                setIsConfirmation(false)
                if (Array.isArray(res?.data?.length) && res?.data?.length > 0) {
                    setTableData(res?.data)
                    setIsConfirm(true)
                }
                else {
                    setIsConfirm(false)
                    // callBackFn()
                    // setSubmitSuccessful(true)'
                   
                    dispatch(openModal(MODALS.INFO_MODAL,
                        {
                            parentClass: "center-modal confirmation-modal",
                            info: `${selectedDidis?.length} didis are assigned successfully for ${activeUpcm?.upcmName}.`,
                            // modalTitle: '',
                            showActionBtn: false
                        }))
                }
            }
            else
                dispatch(setApiError())
            dispatch(showLoaderAction(false));

        } catch (error) {
            dispatch(showLoaderAction(false));
            console.error(error)
            catchErrorHandler(
                error, 
                dispatch(setApiError()),
                {
                    actionType: "UPDATE",
                    actionStatusType:"ERROR",
                    dataChangeDetails: [{
                        "entityId": selectedDidis,
                        "entityName": "Didi",
                        "fieldName": "UpcmId",
                        "oldValue": "null",
                        "newValue": activeUpcm?.id
                    }
                    ]
                }
            );
        }
    };

    const handleSelect = (e, value) => {
        let updatedDidiList = [...selectedDidis];
        if (e.target.checked)
            updatedDidiList.push(value)
        else
            updatedDidiList = updatedDidiList.filter(x => x !== value)

        setSelectedDidis(updatedDidiList)
    }
    const loadDidi = useCallback(debounce(fetchUnassignedDidis, 1000), []);
    const handleSearchFilter = (value) => {
        setUpcmSearch(value)
        loadDidi(blockId, value)
    }

    return (submitSuccessful || isConfirmation) ?
        <div>
            <ModalBody>
                { isApiError && <FallbackComp className="fallback-red" /> }
                {submitSuccessful && <div className='add-didi-confirmation'><p>Didi assigned successfully</p></div>}
                {isConfirmation && <div className='add-didi-confirmation'><p>{activeUpcm?.upcmName} currently has {activeUpcm?.totalDidiCount} didis assigned. Assigning these <span className="brown-bold">{selectedDidis?.length} didis</span> will total {activeUpcm?.upcmName}'s count to <span className="brown-bold">{activeUpcm?.totalDidiCount + selectedDidis?.length}</span></p></div>}
            </ModalBody>
            {isConfirmation && <ModalFooter>
                <button className="secondary-btn ml" onClick={() => setIsConfirmation(false)}>
                    Cancel
                </button>
                <button disabled={selectedDidis.length <= 0} className="primary-btn" onClick={updatedUpcm}>
                    Add
                </button>
            </ModalFooter>}
        </div>
        :
        <div>
            <ModalBody>
                {
                    isConfirm ? <>
                        <p className='ph-25'>Beneficiaries are assigned successfully, although the mapping of following  hamlet/village & UPCM already exist, do you want to proceed with <b>{activeUpcm?.upcmName}</b> ?</p>
                        <br />
                        <div className='upcm-confirmation-list-outer'>
                            <Table
                                className='pending-table upcm-confirm-list'
                                columns={TableColumns()}
                                data={tableData}
                                checkbox={false}
                            />
                        </div>
                    </>
                        :
                        <>
                            <SearchBar
                                handleOnChange={e => handleSearchFilter(e.target.value)}
                                placeholder='Search by Didi or Village'
                                value={upcmSearch}
                            />
                            <div className='didi-card-wrap'>
                                {
                                    didiList?.length > 0 && didiList?.map(item => <SelectDidiCard
                                        id={item?.id}
                                        key={item?.id}
                                        name={item?.name}
                                        address={item?.houseNumber}
                                        tola={item?.tola}
                                        village={item?.villageName}
                                        selectedDidis={selectedDidis}
                                        handleSelect={handleSelect}
                                        didiImgUrl={item?.crpImage}
                                    />)
                                }

                            </div>
                        </>
                }
            </ModalBody>
            <ModalFooter>
                <button className="secondary-btn ml" onClick={closePopup}>
                    Cancel
                </button>
                <button disabled={selectedDidis.length <= 0} className="primary-btn" onClick={() => { isConfirm ? handleUpcmUpdate() : setIsConfirmation(true)}}>
                    Add
                </button>
            </ModalFooter>
        </div>


}
export default DidiSelectionModal   