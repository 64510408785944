import React, { useEffect, useState } from 'react';
import { ModalBody } from 'reactstrap';
import './index.scss';

const BeneficiaryImageModal = ({ modalParameters: { images, baselineImage, identityInfo } }) => {
    const [baselineImg, setBaselineImg] = useState([]);
    const bpcImage = images.userBasedImageDTOList.find(item => item.imageName === 'BPC')?.imageUrl || null;;
    const crpImage = images.userBasedImageDTOList.find(item => item.imageName === 'CRP')?.imageUrl || null;
    const baselinePhoto = identityInfo?.details?.find((item) => item.name === "Baseline photo");
    useEffect(() => {
        if (Array.isArray(baselineImage)) {
            // Avoid re-adding duplicate images by filtering out the ones already in state
            const newImages = baselineImage.filter(
                (newImg) => !baselineImg.some((img) => img.value === newImg.value)
            );
            // Update state with non-duplicate images
            setBaselineImg((prev) => [...prev, ...newImages]);
        }
    }, [baselineImage]);

    return (
        <div className='upload-modal'>
            <ModalBody>
            <div>  
            {crpImage && (
            <>
            <h4 className='beneficiary-img-heading'>Selection - CRP</h4>
            <div className='beneficiary-image-style'>
                    <img className='beneficiary-image' src={crpImage} alt="CRP Image" />
            </div>
              </>
            )}
            {bpcImage && (
            <>
            <h4 className='beneficiary-img-heading bpc-heading'>Selection - BPC</h4>
            <div className='beneficiary-image-style'>
                    <img className='beneficiary-image' src={bpcImage} alt="BPC Image" />
            </div>
            </>
                        )
            }
            </div>
            <br />
            {baselinePhoto && baselinePhoto?.values.length > 0 && (
            <>
            <h4 className="beneficiary-img-heading">Baseline Images</h4>
             <div className="beneficiary-image-style">
              {baselineImg.map((image, key) => (
                 <img
                className="beneficiary-image"
                key={key}
                src={image.value}
                alt={`Baseline Image ${key + 1}`}
                 />
                ))}
             </div>
            </>
            )}
                <br />
            </ModalBody>
        </div>
    );
};

export default BeneficiaryImageModal;