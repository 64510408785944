import React from 'react';
import edit from '../../../assets/images/edit-icon-black.svg'
import deleteIcon from '../../../assets/images/delete-icon-red.svg'
import Image from '../../../components/Common/Image';
import dummyImg from '../../../assets/images/defaultLivelihoodIcon.png'
import SuperAdminImageWithoutToken from '../SuperAdminImageWithoutToken';

const TableColumns = ({handleCreateLivelihoodPage, handleDelete}) => {

    const handleArrayOperation = (row, key) => {
        let data;
        switch (key) {
            case 'assets':
                const assets = row?.assets?.map(asset => asset?.displayName);
                return assets && assets.length > 0 ? assets.join(', ') : '--';
            case 'products':
                const products = row?.products?.map(asset => asset?.displayName);
                return products && products.length > 0 ? products.join(', ') : '--';
            default:
                data = []
        }
    }

    return [
        {
            key: "image",
            text: ``,
            formatter: (cell, row, rowIndex) => {
                const imgUrl = cell;
                return (
                    <SuperAdminImageWithoutToken imgUrl={imgUrl} placeholder={dummyImg} isPreview={false} alt='Livelihood img' className='livelihood-icon-img' />

                )

            },
        },
        {
            key: "id",
            text: `Livelihood ID`,
            isSort: true
        },{
            key: "name",
            text: `Livelihood Name`,
            isSort: true
        },
        {
            key: "assets",
            text: `Asset Type`,
            isSort: false,
            formatter: (cell, row, rowIndex) => {
                return handleArrayOperation(row,"assets")
            }
        },{
            key: "products",
            text: `Product Type`,
            isSort: false,
            formatter: (cell, row, rowIndex) => {
                return handleArrayOperation(row,"products")
            }
        },
        {
            key: "eventsCounts",
            text: `Event Count`,
            isSort: true,
        },
        {
            key: "action",
            text: `Action`,
            isSort: false,
            formatter: (cell, row, rowIndex) => {
                return <div className='livelihood-icon-wrap'>
                <img src={edit} alt="arrow" onClick={() => handleCreateLivelihoodPage(true, true, row)} />
                <img src={deleteIcon} alt="delete" height="16" onClick={()=> handleDelete(row?.id)} />
            </div>
            },
        },

    ]
}
export default TableColumns;