import React, { useState } from 'react'
import { ModalBody, ModalFooter } from 'reactstrap'
import Table from '../../Table'
import './style.scss'
import TableColumns from './TableColumns'
import { commonService } from '../../../../services/common'
import GlobalConfig from '../../../../config/globalconfig';
import { setApiError } from '../../../../storeManager/common/actions'
import { catchErrorHandler } from '../../../../services/commonUtils'
import PreLoader from '../../Preloader'
import { useDispatch } from 'react-redux'

const AddValidationModal = ({ modalParameters: { message, tableData, validationDetails, isEdit = false, onSuccess, handleSetToastMessage }, closePopup }) => {

    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedValidationId, setSelectedValidationId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const handleRadioChange = (row) => {
        setSelectedRow(row?.id);
        setSelectedValidationId(row?.expression)
      };

      const handleAddLivelihoodValidation = async () =>{
        setIsLoading(true);
        try {
            const payload = {
                "programId": validationDetails?.programId,
                "livelihoodId": validationDetails?.livelihoodId,
                "validationId": selectedValidationId
            }
          const res = await commonService.addLivelihoodValidation(payload);
          if (res?.data?.message === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
              //   console.log(res?.data?.data,"tableData1")
              handleSetToastMessage(res?.data?.data)
            onSuccess();
            closePopup()
        }
        else {
            handleSetToastMessage("")
            dispatch(setApiError())
        }
          setIsLoading(false);
        } catch (error) {
          handleSetToastMessage("")
          console.error(error);
          catchErrorHandler(error, dispatch(setApiError()));
          setIsLoading(false);
        }
    }

    if (isLoading) {
        return <PreLoader />;
    }

  return (
    <div className='upload-modal didi-livelihood-validation-modal'>
        <ModalBody>
            <div className='livelihood-validation-outer'>
            <span className='livelihood-validation-outer-text'>{message}</span>
            <Table
                    className='livelihood-validation-modal-table pending-table mapping-list'
                    columns={TableColumns(handleRadioChange, selectedRow)}
                    data={tableData}
                    checkbox={false}
                    selectAll={false}
                />
            </div>
        
        </ModalBody>
        <ModalFooter>
            <button className="secondary-btn ml" onClick={closePopup}>
                Cancel
            </button>
            <button className="primary-btn" onClick={handleAddLivelihoodValidation}>
            {isEdit ? "Save" : "Add" }
            </button>
            </ModalFooter>
</div>
  )
}

export default AddValidationModal