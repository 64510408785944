import { SORTING_FLAG, API_ERROR, API_ERROR_RESET, REF_VALUE, ROLE_VALUE, IS_LOADER, LEFT_ARROW, RIGHT_ARROW, ACTIVE_GEO_FILTERS, CURRENT_GEO_REF,BENEFICIARY_ACTIVE_FILTER_STATUS, BREADCRUMB_DATA, MISSION_TASK_REGENERATE , IMAGE_DATA} from './constants';

export const setApiError = () => ({ type: API_ERROR });
export const setRefValue = (data) => {
    return { type: REF_VALUE, data: data }
};

export const setRoleValue = (dataObj) => {
    return { type: ROLE_VALUE, data: dataObj }
 };
 export const resetApiError = () => ({ type: API_ERROR_RESET });
export const showLoaderAction = (data) => {
    return { type: IS_LOADER, data: data }
};
export const leftArrowAction = (data) => {
    return { type: LEFT_ARROW, data: data }
};
export const rightArrowAction = (data) => {
    return { type: RIGHT_ARROW, data: data }
};
export const setActiveGeoFilters = (data) => {
    return { type: ACTIVE_GEO_FILTERS, data: data }
};

export const setCurrentGeoRef = (data) => {
    return { type: CURRENT_GEO_REF, data: data }
}

export const setTableSortingFlag = (data) => {
    return { type: SORTING_FLAG, data: data }
};
export const setActiveStatusFilter = (data) => {
    return { type: BENEFICIARY_ACTIVE_FILTER_STATUS, data: data }
};

export const setBreadCrumbData = (payload) => {
    return { type: BREADCRUMB_DATA, data: payload}
};

export const handleBreadCrumbData = (responseData) => (dispatch, getState) => {
    const state = getState();
    const _breadCrumbData = state.commonReducer.breadCrumbData || {}
    const {role, refId, data} = responseData

    if(role && refId) {
        _breadCrumbData[role+'_'+refId] = data
    }
    
    dispatch( setBreadCrumbData(_breadCrumbData))
}

export const setActiveRegenerate = (data) => {
    return { type: MISSION_TASK_REGENERATE, data: data }
};
export const setImageData = (payload) => {
    return {type : IMAGE_DATA, data: payload}
};

export const handleImageData  = (responseData) => (dispatch, getState) => {
   const state = getState();
    const _data = state.commonReducer.imageData || {}
    const {imgUrl, imgSrc} = responseData
    if(imgUrl) {
        _data[imgUrl] = imgSrc
    }
    dispatch( setImageData(_data))
}
