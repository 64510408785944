import { AxiosService } from '../apiService';
import { getApi, postApi, postMultipartApi, deleteApi } from './repository';
import GlobalConfig from '../../config/globalconfig'
// import box from '../../mocks'

class CommonService {

  fetchData(obj) {
    if (obj.mock && obj.mock === true) {
      getApi.url = `/mocks/${obj.apiPath}.json`;
    } else {
      getApi.url = obj.apiPath;
    }
    const api = new AxiosService(getApi);
    return api.doAjax();
  }

  getVillageList = (payload) => {
    const requestObj = { ...postApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/read-api/approval/village/view`;
    requestObj.params = payload;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  getActionTakenList = (payload) => {
    const requestObj = { ...postApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/read-api/approval/crp-group/view`;
    requestObj.params = payload;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  getBpcVillageList = (payload) => {
    const requestObj = { ...postApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/read-api/bpc-web/reverification/village-list`;
    requestObj.params = payload;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  getBpcVoidVillageList = (payload) => {
    const requestObj = { ...postApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/read-api/bpc-web/void/village-list`;
    requestObj.params = payload;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  villageAction = (payload) => {
    const requestObj = { ...postApi };
    requestObj.params = payload;
    requestObj.url = `${GlobalConfig.API_ROOT}/write-api/approval/village/update-status`;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  didiAction = (payload) => {
    const requestObj = { ...postApi };
    requestObj.params = payload;
    requestObj.url = `${GlobalConfig.API_ROOT}/write-api/beneficiary/edit`;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  getBeneficiaryVillageList = (payload) => {
    const requestObj = { ...postApi };
    requestObj.params = payload;
    requestObj.url = `${GlobalConfig.API_ROOT}/read-api/web/beneficiary/village-list`;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  fetchDidiList = (id) => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/read-api/web/beneficiary/view?villageId=${id}`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  approvalVillageListTab2 = (payload) => {
    const requestObj = { ...postApi };
    requestObj.params = payload;
    requestObj.url = `${GlobalConfig.API_ROOT}/read-api/approval/village-list`;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  fetchDidiListTab2 = (id) => {
    const reqObj = { ...postApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/read-api/approval/beneficiary-view?villageId=${id}`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  getDocument = url => {
    const reqObj = { ...getApi };
    reqObj.url = url;
    reqObj.responseType = 'blob';
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  approvalVillageListTab3 = (payload) => {
    const requestObj = { ...postApi };
    requestObj.params = payload;
    requestObj.url = `${GlobalConfig.API_ROOT}/read-api/approval/wait/village-list`;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  
  fetchDidiListTab3 = (id) => {
    const reqObj = { ...postApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/read-api/approval/beneficiary-wait-view?villageId=${id}`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  fetchDidiListTab4 = (payload) => {
    const reqObj = { ...postApi };
    reqObj.params = payload;
    reqObj.url = `${GlobalConfig.API_ROOT}/read-api/approval/send-vo-endorsement`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  VillageDidiActions = (payload) => {
    const reqObj = { ...postApi };
    reqObj.params = payload;
    reqObj.url = `${GlobalConfig.API_ROOT}/read-api/approval/update-beneficiary-status`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  getFormDataByVillage = (id,type) => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/read-api/web/forms/view?villageId=${id}&formType=FORM_${type}`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  addMoreDidis = (payload) => {
    const reqObj = { ...postApi };
    reqObj.params = payload;
    reqObj.url = `${GlobalConfig.API_ROOT}/write-api/beneficiary-process-status/update/beneficiary`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  bpcVillageWithDidiList = (payload) => {
    const reqObj = { ...postApi };
    reqObj.params = payload;
    reqObj.url = `${GlobalConfig.API_ROOT}/read-api/bpc-web/list-vo-endorsement`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  bpcDidiAction = (payload) => {
    const requestObj = { ...postApi };
    requestObj.params = payload;
    requestObj.url = `${GlobalConfig.API_ROOT}/write-api/beneficiary/edit`;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  getVoVillageList = (payload) => {
    const requestObj = { ...postApi };
    requestObj.params = payload;
    requestObj.url = `${GlobalConfig.API_ROOT}/read-api/bpc-web/vo/village-list`;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  getVoVillageListTab4 = (payload) => {
    const requestObj = { ...postApi };
    requestObj.params = payload;
    requestObj.url = `${GlobalConfig.API_ROOT}/read-api/approval/village-list-vo`;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  getBpcTabCount = (payload) => {
    const requestObj = { ...postApi };
    requestObj.params = payload;
    requestObj.url = `${GlobalConfig.API_ROOT}/read-api/bpc-web/count-summary`;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  getApprovalCountSummary = (payload) => {
    const requestObj = { ...postApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/read-api/approval/count-summary`;
    requestObj.params = payload;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  uploadFormCandD = (payload) => {
    const requestObj = { ...postMultipartApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/write-api/form/uploadDocument`;
    requestObj.params = payload;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  getSelectedDidiCalc = (payload) => {
    const requestObj = { ...postApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/read-api/approval/sub-count`;
    requestObj.params = payload;
    const api = new AxiosService(requestObj);
    return api.doAjax()

  }
  logOutUser(){
    const requestObj = { ...postApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/auth-api/user/logout`;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  uploadUsers = (payload, userType, stateId) => {
    const requestObj = { ...postMultipartApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/user-management/users/uploadUsers?userType=${userType}&stateId=${stateId}`;
    requestObj.params = payload;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  downloadUsers = (userType, stateId) => {
    const requestObj = { ...getApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/user-management/users/downloadUsers?userType=${userType}&stateId=${stateId}`;
    requestObj.responseType= 'blob';
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  uploadLanguage = (payload, languageId, stateId) => {
    const requestObj = { ...postMultipartApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/user-management/villages/uploadVillages?stateId=${stateId}&languageId=${languageId}`;
    requestObj.params = payload;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  downloadLanguage = (languageId, stateId) => {
    const requestObj = { ...getApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/user-management/villages/downloadVillages?stateId=${stateId}&languageId=${languageId}`;
    requestObj.responseType= 'blob';
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  downloadDidiDownload = (stateId,userId) => {
    const requestObj = { ...getApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/user-management/vo/download?referenceId=${stateId}&userId=${userId}`;
    requestObj.responseType= 'blob';
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  getLanguageList = () => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/read-api/config/language/get`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }

  downloadBeneficiaryList = (referenceId, referenceType, fileType) => {
    const requestObj = { ...getApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/read-api/web/downloadBeneficiary?referenceId=${referenceId}&referenceType=${referenceType}&fileType=${fileType}`;
    // requestObj.responseType= 'blob';
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  villageActionReject = (payload) => {
    const requestObj = { ...deleteApi };
    requestObj.params = payload;
    requestObj.url = `${GlobalConfig.API_ROOT}/write-api/data-cleanup/delete`;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  getUpcmVillageLIst = (villageId) => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/didi-service/upcm/fetch/village/${villageId}`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  getUpcmDidisList = (isAssigned, id, offset, limit, searchStr="") => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/didi-service/upcm/didi/fetch?isAssigned=${isAssigned}&villageId=${id}&offset=${offset}&limit=${limit}&searchString=${searchStr}`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  getBlockUpcmList = (id) => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/didi-service/upcm/fetch/${id}`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  updateUpcmDidis = (payload) => {
    const requestObj = { ...postApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/didi-service/upcm/saveOrUpdate`;
    requestObj.params = payload;
    const api = new AxiosService(requestObj);
    return api.doAjax()

  }
  updateUpcmOnConfirm = (payload) => {
    const requestObj = { ...postApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/didi-service/upcm/assign/task`;
    requestObj.params = payload;
    const api = new AxiosService(requestObj);
    return api.doAjax()

  }
  getUpcmList = (id) => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/didi-service/upcm/fetch/597`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  
  getDidisAssignedToUpcm = (id, offset, limit, searchStr = "") => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/didi-service/upcm/fetch/benificaryMetaData?offset=${offset}&limit=${limit}&upcmId=${id}&searchString=${searchStr}`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  
  getUnassignedDidiOfBlock = (blockId, searchStr="") => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/didi-service/upcm/fetch/didi/unassigned?blockId=${blockId}&searchString=${searchStr}`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  
  uploadBackupFile = (payload) => {
    const requestObj = { ...postMultipartApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/write-api/backup/fileImageUpload`;
    requestObj.params = payload;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  uploadBackupImage = (payload) => {
    const requestObj = { ...postMultipartApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/write-api/backup/fileUpload`;
    requestObj.params = payload;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  BaselineSurveyCompletedBeneficiaryDetail = (payload) => {
    const requestObj = { ...postApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/survey-service/baseline-web/survey-completed-beneficiary-detail`;
    requestObj.params = payload;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  
  HamletSurveyCompletedDetail = (payload) => {
    const requestObj = { ...postApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/survey-service/hamlet-web/hamlet-completed`;
    requestObj.params = payload;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  DownloadbeneficiarySurvey = (subjectId, subjectName, surveyId, upCmId) => {
    const requestObj = { ...getApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/survey-service/baseline-web/download/beneficiary-survey?subjectId=${subjectId}&surveyId=${surveyId}&subjectName=${subjectName.split(' ')[0]}&doerId=${upCmId}`;
    requestObj.responseType= 'blob';
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  SurveyDropdownList = (payload) => {
    const requestObj = { ...postApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/survey-service/survey-list`;
    requestObj.params = payload;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  DownloadHamletSurvey = (subjectId, subjectName, surveyId, upCmId) => {
    const requestObj = { ...getApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/survey-service/baseline-web/download/beneficiary-survey?subjectId=${subjectId}&surveyId=${surveyId}&subjectName=${subjectName.split(' ')[0]}&doerId=${upCmId}`;
    requestObj.responseType= 'blob';
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  getBeneficiaryPersonalDetail = (id) => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/didi-service/beneficiary/personalDetails?beneficiaryId=${id}`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  getBeneficiaryTagDetail = (id, villId) => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/didi-service/beneficiary/profile?beneficiaryId=${id}&villageID=${villId}`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  uploadBaselineBackupFileData = (payload, options) => {
    const requestObj = { ...postMultipartApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/survey-service/survey/data`;
    requestObj.params = payload;
    const api = new AxiosService(requestObj, options);
    return api.doAjax()
  }
  uploadBaselineBackupFileSaveStatus = (payload, options) => {
    const requestObj = { ...postMultipartApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/mission-service/mission/saveStatus`;
    requestObj.params = payload;
    const api = new AxiosService(requestObj, options);
    return api.doAjax()
  }
  createSmallGroup = (payload, options) => {
    const requestObj = { ...postApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/didi-service/smallgroup/create`;
    requestObj.params = payload;
    const api = new AxiosService(requestObj, options);
    return api.doAjax()
  }

  smallGroupList = (payload, options) => {
    const reqObj = { ...postApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/didi-service/smallgroup/groups`;
    reqObj.params = payload;
    const api = new AxiosService(reqObj, options);
    return api.doAjax()
  }

  getUngroupedDidiList =  (upcmId) => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/didi-service/upcm/view?userId=${upcmId}&ungrouped=1`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }

  smallGroupDidiMapping = (payload, options) => {
    const requestObj = { ...postApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/didi-service/smallgroup/assign-unassign`;
    requestObj.params = payload;
    const api = new AxiosService(requestObj, options);
    return api.doAjax()
  }

  smallGroupDidiList = (groupId) => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/didi-service/smallgroup/group-beneficiaries?sgId=${groupId}`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }

  smallGroupInfoBar = (payload, options) => {
    const requestObj = { ...postApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/didi-service/smallgroup/meta-data`;
    requestObj.params = payload;
    const api = new AxiosService(requestObj, options);
    return api.doAjax()
  }

  getSmallGroupAttedance = (payload, options) => {
    const requestObj = { ...postApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/didi-service/smallgroup/attendance-history`;
    requestObj.params = payload;
    const api = new AxiosService(requestObj, options);
    return api.doAjax()
  }

  searchDidiSmallGroup = (params) => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/didi-service/smallgroup/search-beneficiaries?referenceId=${params.refId}&referenceType=${params.refType}&search=${params.search}&searchFlag=${params.searchFlag}`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  
  DownloadMultipleDataBaselineHamlet = (payload) => {
    const requestObj = { ...postApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/survey-service/baseline-web/download/multiple-beneficiary-survey`;
    requestObj.params = payload;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  CheckDownloadStatusMultipleDataBaselineHamletSurve = (requestId) => {
    const requestObj = { ...getApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/survey-service/baseline-web/checkDownloadStatus?requestId=${requestId}`;
    requestObj.responseType= 'blob';
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
    supersetApi = (id) => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/read-api/superset/guest-token?id=${id}`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  CheckDownloadStatusBenificiary = (requestId, fileType) => {
    const requestObj = { ...getApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/read-api/web/checkDownloadStatus?requestId=${requestId}&fileType=${fileType}`;
    requestObj.responseType= 'blob';
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  fetchProgramDidiList = (id, status, type, programName) => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/didi-service/didi-program/byProgram?programId=${id}&status=${status}&type=${type}&programName=${programName}`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  CreateProgramSuperAdmin = (payload) => {
    const requestObj = { ...postApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/mission-service/program/program`;
    requestObj.params = payload;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  fetchProgramWidgetList = (programId) => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/didi-service/didi-program/programDetails/ByProgramId?programId=${programId}`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  fetchLivelihoodWidgetList = (programId) => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/livelihood-service/livelihood/program-mapped-livelihood?programId=${programId}`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  fetchLivelihoodMappedList = (id,status) => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/livelihood-service/livelihood/config?programId=${id}&allLivelihood=${status}`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  downloadSuperAdminProgram = (programId, programName) => {
    const requestObj = { ...getApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/didi-service/didi-program/download/didi-program-mapping?programId=${programId}&programName=${programName}`;
    requestObj.responseType= 'blob';
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  fetchProgramMissionList = (id, status) => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/mission-service/mission/program-mission?programId=${id}&mapped=${status}`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  programLivelihoodMapUnmap = (payload) => {
    const reqObj = { ...postApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/livelihood-service/livelihood/map/program-livelihood`;
    reqObj.params = payload;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  programLivelihoodAssetInfo = (payload) => {
    const reqObj = { ...postApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/livelihood-service/livelihood/map/program-livelihood-asset`;
    reqObj.params = payload;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  programMissionMapUnmap = (payload) => {
    const reqObj = { ...postApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/mission-service/mission/map/program-mission`;
    reqObj.params = payload;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  deleteLivelihood = (payload) => {
    const requestObj = { ...postApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/livelihood-service/livelihood/delete`;
    requestObj.params = payload;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  livelihoodSaveUpdate = (payload) => {
    const requestObj = { ...postApi };
    requestObj.url = `${GlobalConfig.API_ROOT}/livelihood-service/livelihood/save`;
    requestObj.params = payload;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }
  livelihoodDataList = (programId, allLivelihood) => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/livelihood-service/livelihood/config?programId=${programId}&allLivelihood=${allLivelihood}`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  eventTypeOptionList = () => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/livelihood-service/eventsType/list`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  fetchMissionList = () => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/mission-service/mission/get/mission-list`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  getDidiLivelihoodDetail = (payload) => {
    const reqObj = { ...postApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/didi-service/assetJournal/incomeExpense/summary`;
    reqObj.params = payload;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  regenerateTaskStatus = (payload) => {
    const requestObj = { ...postApi };
    requestObj.params = payload;
    requestObj.url = `${GlobalConfig.API_ROOT}/mission-service/mission/regenerate-tasks`;
    const api = new AxiosService(requestObj);
    return api.doAjax()
  }

  searchGlobalBeneficiary =(params) => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/read-api/web/search-village-beneficiaries?referenceId=${params.refId}&referenceType=${params.refType}&search=${params.search}`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  assetTypeOptionList = () => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/livelihood-service/assets/asset-types`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  productTypeOptionList = () => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/livelihood-service/products/products-type`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  livelihoodNameOptionList = () => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/livelihood-service/livelihood/livelihood-type`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
  eventNameOptionList = () => {
    const reqObj = { ...getApi };
    reqObj.url = `${GlobalConfig.API_ROOT}/livelihood-service/event/event-name`;
    const api = new AxiosService(reqObj);
    return api.doAjax()
  }
}

export const commonService = new CommonService();





