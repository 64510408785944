import { SORTING_FLAG, API_ERROR, REF_VALUE, ROLE_VALUE, API_ERROR_RESET, IS_LOADER, LEFT_ARROW, RIGHT_ARROW, ACTIVE_GEO_FILTERS, CURRENT_GEO_REF,BENEFICIARY_ACTIVE_FILTER_STATUS ,BREADCRUMB_DATA, MISSION_TASK_REGENERATE, IMAGE_DATA} from './constants';

const initialState = {
  isApiError: false,
  referenceValue: '',
  roleValue: {
    roleName: '',
    roleId: '',
  },
  showLoader:false,
  isLeftArrow:false,
  isRightArrow:true,
  activeGeoFilter: {
    state: {},
    district: {},
    block: {},
    village: {}
  },
  currentGeoRef:{},
  tableSortingFlag:{ key: null, isAscending: false },
  beneficiaryActiveFilterStatus: [],
  breadCrumbData: {},
  taskRegenerateActiveStatus: {value: false, missionId: 0, programId: 0},
  imageData: {}
};

export default function commonReducer(state = initialState, action) {
  switch (action.type) {
    case API_ERROR:
      return {
        ...state,
        isApiError: true,
      };
      case API_ERROR_RESET:
      return {
        ...state,
        isApiError: false,
      };
    case REF_VALUE:
      return {
        ...state,
        referenceValue: action.data,
      };
    case ROLE_VALUE:
      return {
        ...state,
        roleValue: action.data,
      };
    case IS_LOADER:
      return {
        ...state,
        showLoader: action.data,
      };
    case LEFT_ARROW:
      return {
        ...state,
        isLeftArrow: action.data,
      };
    case RIGHT_ARROW:
      return {
        ...state,
        isRightArrow: action.data,
      };
    case ACTIVE_GEO_FILTERS:
      return {
        ...state,
        activeGeoFilter: action.data,
      };
    case CURRENT_GEO_REF:
      return{
        ...state,
        currentGeoRef: action.data,
      }
      case SORTING_FLAG:
      return {
        ...state,
        tableSortingFlag: action.data,
      };
      case BENEFICIARY_ACTIVE_FILTER_STATUS:
        return{
          ...state,
          beneficiaryActiveFilterStatus: action.data
        }
        case BREADCRUMB_DATA: 
        return {
          ...state,
          breadCrumbData: action.data
        }
        case MISSION_TASK_REGENERATE:
          return{
            ...state,
            taskRegenerateActiveStatus: action.data
          }
      case IMAGE_DATA: 
      return {
        ...state,
        imageData: action.data
      }
    default:
      return state;
  }
}
