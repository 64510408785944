import React from 'react';
import './style.scss';

const FallbackComp = (props) => {
    const { message = null, className = '' } = props;
return <div className={`fallback ${className}`}>
    <h4>{message ? message : 'Something went wrong! Please try after sometime.'}</h4>
</div>
}

export default FallbackComp;