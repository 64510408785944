import React, { useEffect, useState } from 'react'
import Header from '../../components/Common/Header'
import './style.scss'
import SearchBar from '../../components/SearchBar'
import Table from '../../components/Common/Table';
import TableColumns from './TableColumns';
import { useDispatch } from 'react-redux';
import { commonService } from '../../services/common';
import { catchErrorHandler } from '../../services/commonUtils';
import { setApiError } from '../../storeManager/common/actions';
import GlobalConfig from '../../config/globalconfig';
import PreLoader from '../../components/Common/Preloader';
import axios from 'axios';

const DownloadsFlow = () => {

    const [searchData, setSearchData] = useState('');
    const [searchedDataList, setSearchedDataList] = useState([]);
    const [downloadDataList, setDownloadDataList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const { referenceId } = JSON.parse(localStorage.getItem("userAuth")) || "";
    const refId = referenceId[0]?.id;

    const fetchDownloadDataList = async (refId) => {
        setIsLoading(true);
        try {
            const response = await commonService.fetchDownloadFlowDataList(refId);
            const res = response?.data;
            if (res?.message === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                setDownloadDataList(res?.data);
            } else {
                dispatch(setApiError());
                setDownloadDataList([]);
            }
        } catch (error) {
            setDownloadDataList([]);
            console.error(error);
            catchErrorHandler(error, dispatch(setApiError()));
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(()=>{
        fetchDownloadDataList(refId)
    },[])

    const handleSearchFilter = (value) => {
        let filteredList
        setSearchData(value)
        filteredList = downloadDataList.filter(x => x?.reportName.toLowerCase().includes(value.toLowerCase()))
        setSearchedDataList(filteredList)
    }

    const getDownloadWithoutToken = (imgUrl) => {
            const axiosInstance = axios.create();
    
            axiosInstance({
                url: imgUrl,
                method: 'GET',
                responseType: 'blob',
                transformRequest: [(data, headers) => {
                    delete headers['authorization'];
                    return data;
                }],
            }).then((res) => {
                const fileUrl = URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' }));
                const anchor = document.createElement('a');
                anchor.href = fileUrl;
                // anchor.download = 'downloaded_file';
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                URL.revokeObjectURL(fileUrl);
            }).catch((err) => {
                console.error('Image download error:', err);
            });
    };
    
    const handleDownload = async (requestId) => {
        try {
            const response = await commonService.DownloadFlowDataDownload(refId, requestId);
            const res = response?.data;
            if (res?.message === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                getDownloadWithoutToken(res?.data)
            } else {
                dispatch(setApiError());
            }
        } catch (error) {
            console.error(error);
            catchErrorHandler(error, dispatch(setApiError()));
        }
    }

    if (isLoading) {
        return <PreLoader />;
    }

    return (
        <div className="main-page download-flow fixed-header">
            <Header />
            <div className="main-section downloads-flow-main">
                <div className='download-flow-container'>
                    <div className='download-flow-search-outer'>
                    <SearchBar
                                handleOnChange={(e) => handleSearchFilter(e.target.value)}
                                placeholder='Search file name'
                                value={searchData}
                            />
                        <button className='primary-btn refresh-download' onClick={()=> fetchDownloadDataList(refId)}>Refresh</button>
                    </div>
                    <div className='download-flow-body'>
                    <Table className='download-flow-table pending-table' 
                    columns={TableColumns({handleDownload})}
                    data={searchData ? searchedDataList : downloadDataList} 
                    checkbox={false} 
                    selectAll={false} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DownloadsFlow