import React from 'react';
import {
    Modal,
    ModalHeader,
    // ModalBody 
} from 'reactstrap';
import ConfirmationModal from './ConfirmationModal';
import UploadModal from './UploadModal';
import ViewModal from './ViewModal';
import ReverificationConfirmationModal from './ReverificationConfirmationModal';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal } from '../../../storeManager/modal/actions';
import cross from '../../../assets/images/cross.svg';
import { MODALS } from "../../../storeManager/modal/constants";
import './style.css'
import InfoModal from './InfoModal';
import UserUploadModal from './UserUploadModal';
import ErrorListModal from './ErrorListModal';
import RedirectModal from './RedirectModal';
import LanguageUploadModal from './LanguageUploadModal';
import UpcmSelectionModal from './UpcmSelectionModal';
import LivelihoodDeleteInfoModal from './LivelihoodDeleteInfoModal';
import DidiSelectionModal from './DidiSelectionModal';
import BackupFileUploadModal from './BackupFileUploadModal';
import BackupImageUploadModal from './BackupImageUploadModal';
import MissionAnswerListModal from './MissionAnswerListModal';
import BaselineBackupFileUploadModal from './BaselineBackupFileUploadModal';
import DidiSGSelectionModal from './DidiSGSelectionModal';
import AddEditSmallGroupModal from './AddEditSmallGroupModal';
import SuccessModal from './SuccessModal';
import ReassignSmallGroupModal from './ReassignSmallGroupModal';
import BaselineImageUploadModal from './BaselineImageUploadModal';
import VoDidiMappingFileUpload from './VoDidiMappingFileUpload';
import CreateProgramModal from './CreateProgramModal';
import SuperAdminProgramDownloadModal from './SuperAdminProgramDownloadModal';
import SuperAdminDeleteInfoModal from './SuperAdminDeleteInfoModal';
import LivelihoodIconUploadModal from './LivelihoodIconUploadModal';
import DidiLivelihoodEditHistoryModal from './DidiLivelihoodEditHistoryModal'
import DidiLivelihoodViewAssetModal from './DidiLivelihoodViewAssetModal';
import BeneficiaryImageModal from './BeneficiaryImageModal';
import BeneficiaryFilterListModal from './BeneficiaryFilterListModal';
import AddValidationModal from './AddValidationModal';

const CustomModal = (props) => {
    const dispatch = useDispatch();
    // const [modal, setModal] = useState(false);
    const { showModal, modalParameters, modalName } = useSelector(state => state.modalReducer)
    const closePopup = () => {
        dispatch(closeModal());
    }
    const showContent = () => {
        if (modalName) {
            switch (modalName) {
                case MODALS.CONFIRMATION_MODAL:
                    return <ConfirmationModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />;
                case MODALS.REVERIFICATION_CONFIRMATION_MODAL:
                    return <ReverificationConfirmationModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />
                case MODALS.UPLOAD_MODAL:
                    return <UploadModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />;
                case MODALS.VIEW_MODAL:
                    return <ViewModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />;
                case MODALS.INFO_MODAL:
                    return <InfoModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />;
                case MODALS.USER_UPLOAD_MODAL:
                    return <UserUploadModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />;
                case MODALS.LANGUAGE_UPLOAD_MODAL:
                    return <LanguageUploadModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />;
                case MODALS.BACKUPFILE_UPLOAD_MODAL:
                    return <BackupFileUploadModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />;
                case MODALS.BACKUPIMAGE_UPLOAD_MODAL:
                    return <BackupImageUploadModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />;
                case MODALS.ERROR_LIST_MODAL:
                    return <ErrorListModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />;
                case MODALS.REDIRECT_MODAL:
                    return <RedirectModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />;
                case MODALS.UPCM_SELECTION_MODAL:
                    return <UpcmSelectionModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />;
                case MODALS.DIDI_SELECTION_MODAL:
                    return <DidiSelectionModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />;
                case MODALS.MISSION_ANSWER_LIST:
                    return <MissionAnswerListModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />;
                case MODALS.BASELINE_BACKUPFILE_UPLOAD_MODAL:
                    return <BaselineBackupFileUploadModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />;
                case MODALS.VO_DIDI_MAPPING_FILE_UPLOAD:
                    return <VoDidiMappingFileUpload showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />;
                case MODALS.ADD_EDIT_SMALL_GROUP:
                    return <AddEditSmallGroupModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />
                case MODALS.DIDI_SG_SELECTION_MODAL:
                    return <DidiSGSelectionModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />
                case MODALS.SUCCESS_MODAL:
                    return <SuccessModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />
                case MODALS.REASSIGN_SMALL_GROUP_MODAL:
                    return <ReassignSmallGroupModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />
                case MODALS.BASELINE_IMAGE_UPLOAD_MODAL:
                    return <BaselineImageUploadModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />;
                case MODALS.CREATE_PROGRAM_MODAL:
                    return <CreateProgramModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />;
                case MODALS.SUPER_ADMIN_FILE_UPLOAD_MODAL:
                    return <SuperAdminProgramDownloadModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />;
                case MODALS.SUPER_ADMIN_DELETE_INFO_MODAL:
                    return <SuperAdminDeleteInfoModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />;
                case MODALS.CREATE_LIVELIHOOD_ICON_UPLOAD_MODAL:
                    return <LivelihoodIconUploadModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />;
                case MODALS.DIDI_LIVELIHOOD_DATA_EDIT_HISTORY_MODAL:
                    return <DidiLivelihoodEditHistoryModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />;
                case MODALS.DIDI_LIVELIHOOD_DATA_OPEN_COUNT_MODAL:
                    return <DidiLivelihoodViewAssetModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />;
                case MODALS.LIVELIHOOD_DELETE_MODAL:
                    return <LivelihoodDeleteInfoModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />;
                case MODALS.BENEFICIARY_IMAGE_PICS:
                    return <BeneficiaryImageModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />;
                case MODALS.BENEFICIARY_FILTER_LIST_MODAL: 
                    return <BeneficiaryFilterListModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />;
                case MODALS.SUPER_ADMIN_ADD_VALIDATION_MODAL: 
                    return <AddValidationModal showModal={showModal} closePopup={closePopup} modalParameters={modalParameters} />;
                 default:
                    return null
            }
        }
    };
    return (
        <>
            {showContent() && <Modal
                isOpen={showModal}
                shouldCloseOnOverlayClick={true}
                toggle={closePopup}
                className={`modal-wrap ${modalParameters?.parentClass}`}
            >

                <ModalHeader><span className='title'>{modalParameters?.modalTitle}</span> <span className='close-modal-cross' onClick={closePopup} ><img src={cross} alt="close modal icon" /></span></ModalHeader>


                {showContent()}



            </Modal>}
        </>
    )
}

export default CustomModal