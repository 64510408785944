import React from 'react';
import approve from '../../../../assets/images/green-dot.png'
import unmap from '../../../../assets/images/git-pull-request.svg'

const TableColumns = ({handleDelete, setIsModalOpen, setSelectedRow}) => {

    const handleActivityList = (row) => {
        let result;
        if (row?.activityName?.length === 1) {
            result = row?.activityName[0];
        } else {
            let remainingCount = row?.activityName?.length - 1;
            result = (
                <span className='activity-list-count-outer'>
            {row?.activityName[0]}.... <span onClick={() => handleActivityClick(row)} class="activity-list-count">{remainingCount} </span>
            </span>
            )
        }

        return result
    }
    const handleActivityClick = (row) =>{
        setIsModalOpen(true)
        setSelectedRow(row)
    }

    return [
        {
            key: "missionId",
            text: `Mission ID`,
            isSort: true
        },
        {
            key: "orderId",
            text: `Order ID`,
            isSort: true
        },
        {
            key: "missionName",
            text: `Mission Name`,
            isSort: true
        },
        {
            key: "missionDisplayName",
            text: `Display Name`,
            isSort: true
        },
        {
            key: "activityCount",
            text: `Activity Count`,
            isSort: true
        },
        {
            key: "activityName",
            text: `List of Activities`,
            isSort: false,
            formatter: (cell, row, rowIndex) => {
                return handleActivityList(row, cell)
            },
        },
        // {
        //     key: "missionStatus",
        //     text: `Mission Status`,
        //     isSort: true,
        //     formatter: (cell, row, rowIndex) => {
        //         return <div className='mission-status-wrap'><img src={approve} width={18} height={18} alt='icon' /> Active </div>
        //     },
        // },
        {
            key: "action",
            text: `Actions`,
            isSort: false,
            formatter: (cell, row, rowIndex) => {
                return <div className='mission-icon-wrap' onClick={()=> handleDelete(row?.missionId)}><img src={unmap} alt="delete" height="16" /> Un-map </div>
            },
        },
    ]
}
export default TableColumns;