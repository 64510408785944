const InfoBox = ({ boxData , page }) => {
  return (
    <ul className={page === 'ultra-poor' ? "box-section poor-section" : "box-section"}>
      {boxData?.map((boxItem, i) => (
        <li key={i} className={page === 'ultra-poor' ? "box-data poor-data" : "box-data"}>
          <div className="box-left">
            <h2>{boxItem.values}</h2>
            <div className="box-icon">
              <img src={boxItem.imgName} alt={boxItem.name} />
            </div>
          </div>
          <p>{boxItem.name}</p>

        </li>
      ))}
    </ul>
  );
};

export default InfoBox;
