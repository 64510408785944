import React, { useEffect, useState } from 'react';
import { ModalBody } from 'reactstrap';
import './index.scss';

const BeneficiaryImageModal = ({ modalParameters: { images, baselineImage, upcmName } }) => {
    const [baselineImg, setBaselineImg] = useState([]);
    const { bpcImage, crpImage } = images;

    useEffect(() => {
        if (Array.isArray(baselineImage)) {
            // Avoid re-adding duplicate images by filtering out the ones already in state
            const newImages = baselineImage.filter(
                (newImg) => !baselineImg.some((img) => img.value === newImg.value)
            );
            // Update state with non-duplicate images
            setBaselineImg((prev) => [...prev, ...newImages]);
        }
    }, [baselineImage]);

    return (
        <div className='upload-modal'>
            <ModalBody>
                {bpcImage  || crpImage ? 
                <h4 className='beneficiary-img-heading'>Selection Flow</h4> :'No Data'
                 }
                <div className='beneficiary-image-style'>
                    {bpcImage && <img className='beneficiary-image' src={bpcImage} alt="BPC Image" />}
                    {crpImage && <img className='beneficiary-image' src={crpImage} alt="CRP Image" />}
                </div>
                <br />
                {upcmName && <h4 className='beneficiary-img-heading'>Baseline Images</h4>}
                <div className='beneficiary-image-style'>
                    {baselineImg.map((image, key) => {
                        return (
                            <img
                                className='beneficiary-image'
                                key={key}
                                src={image.value}
                                alt={`Baseline Image ${key + 1}`}
                            />
                        );
                    })}
                </div>
                <br />
            </ModalBody>
        </div>
    );
};

export default BeneficiaryImageModal;
