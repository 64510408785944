import React, { useContext, useEffect, useState } from 'react';
import { commonService } from '../../../../services/common';
import { useDispatch } from 'react-redux';
import { catchErrorHandler } from '../../../../services/commonUtils';
import { setApiError } from '../../../../storeManager/common/actions';
import GlobalConfig from '../../../../config/globalconfig';
import SearchBar from '../../../../components/SearchBar';
import NoDataIcon from '../../../../assets/images/blank-page.png';
import './style.scss';
import Table from '../../../../components/Common/Table';
import TableColumns from './TableColumns';
import PreLoader from '../../../../components/Common/Preloader';
import { openModal } from '../../../../storeManager/modal/actions';
import { MODALS } from '../../../../storeManager/modal/constants';
import ToastMsg from '../../../../components/Common/ToastMsg';
import MapLivelihood from './MapLivelihood';
import CustomModal from '../../../../components/Common/Modal/VoDidiMappingFileUpload/customModal';
import AssetIncomeModal from './AssetIncomeModal';
import { ProgramContext } from '..';

const LivelihoodTab = ({ detail }) => {

    const { toastMessage, handleSetToastMessage } = useContext(ProgramContext);

    const [livelihoodList, setLivelihoodList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isMapLivelihoodCheck, setIsMapLivelihoodCheck] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [livelihoodListValue, setLivelihoodListValue] = useState('');
    const [searchedDataList, setSearchedDataList] = useState([]);

    const dispatch = useDispatch();

    const fetchLivelihoodList = async (id) => {
        setIsLoading(true);
        try {
            const response = await commonService.fetchLivelihoodWidgetList(id);
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                setLivelihoodList(res?.data);
            } else {
                dispatch(setApiError());
                setLivelihoodList([]);
            }
        } catch (error) {
            setLivelihoodList([]);
            console.error(error);
            catchErrorHandler(error, dispatch(setApiError()));
        } finally {
            setIsLoading(false);
        }
    };

    const programLivelihoodMapUnmap = async (id, status) => {
        setIsLoading(true);
        const payload = {
            programId: detail?.programId,
            livelihoodIdsList: id,
            map: status,
        };
        try {
            const response = await commonService.programLivelihoodMapUnmap(payload);
            const res = response?.data
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                handleSetToastMessage(res?.data);
                // await fetchLivelihoodList(detail?.programId); // Call fetchLivelihoodList after deletion
            }else if(res?.status === GlobalConfig.MESSAGES_TYPES.FAIL){
                dispatch(openModal(MODALS.LIVELIHOOD_DELETE_MODAL,
                    {
                        parentClass: "super-admin-delete-info center-modal confirmation-modal",
                        modalTitle: `Livelihood ID: ${id}`,
                        status : res?.status,
                        msg : res?.data,
                        type: 'add',
                        referenceId: id,
                    }))
            } else {
                dispatch(setApiError());
                handleSetToastMessage('');
            }
        } catch (error) {
            handleSetToastMessage('');
            console.error(error);
            catchErrorHandler(error, dispatch(setApiError()));
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = (livelihoodId) => {
        dispatch(openModal(MODALS.SUPER_ADMIN_DELETE_INFO_MODAL, {
            parentClass: "super-admin-delete-info center-modal confirmation-modal",
            btnText: "Delete",
            confirmAction: programLivelihoodMapUnmap,
            btnClass: "livelihood-delete",
            id: [livelihoodId],
            status: 0,
            modalTitle: 'Are you sure to unmap livelihood?',
            message: `Un-mapping Livelihood ID: ${livelihoodId}, which consists of all the activities linked to the program will be removed.`,
        }));
    };

    const handleSearchFilter = (value) => {
        let filteredList
        setLivelihoodListValue(value)
        filteredList = livelihoodList.filter(x => x.name.toLowerCase().includes(value.toLowerCase()))
        setSearchedDataList(filteredList)
    }

    const handleAssetChange = () => {
        fetchLivelihoodList(detail?.programId)
    }

    const getValidationList = async ( livelihoodId, isEdit ) =>{
        handleSetToastMessage('');
        const validationDetails = {
            programId: detail?.programId,
            livelihoodId: livelihoodId
        }
        setIsLoading(true);
        try {
          const res = await commonService.livelihoodValidationList();
          if (res?.data?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
            const enhancedTableData = res?.data?.data.map((row, index) => ({
                ...row,
                id: `${row.expression}-${index}`,
              }));

              if(isEdit) { 
                dispatch(openModal(MODALS.SUPER_ADMIN_ADD_VALIDATION_MODAL, {
                    parentClass: "super-admin-add-validation center-modal confirmation-modal",
                    modalTitle: 'Validation',
                    message: `Please select the validations from the DB List.`,
                    tableData: enhancedTableData,
                    validationDetails: validationDetails,
                    handleSetToastMessage: handleSetToastMessage,
                    isEdit: true,
                    closePopup: () => fetchLivelihoodList(detail?.programId),
                    onSuccess: () => fetchLivelihoodList(detail?.programId),
                }));
            } else {
                dispatch(openModal(MODALS.SUPER_ADMIN_ADD_VALIDATION_MODAL, {
                    parentClass: "super-admin-add-validation center-modal confirmation-modal",
                    modalTitle: 'Validation',
                    message: `Please select the validations from the DB List.`,
                    tableData: enhancedTableData,
                    validationDetails: validationDetails,
                    handleSetToastMessage: handleSetToastMessage,
                    closePopup: () => fetchLivelihoodList(detail?.programId),
                    onSuccess: () => fetchLivelihoodList(detail?.programId),
                }));
            }
            }
        else {
            dispatch(setApiError())
        }
          setIsLoading(false);
        } catch (error) {
          console.error(error);
          catchErrorHandler(error, dispatch(setApiError()));
          setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchLivelihoodList(detail?.programId);
    }, [toastMessage]);

    if (isLoading) {
        return <PreLoader />;
    }

    return (
        isMapLivelihoodCheck ? (
            <MapLivelihood setIsMapLivelihoodCheck={setIsMapLivelihoodCheck} detail={detail} />
        ) : (
            <>
                <div className={livelihoodList.length > 0 ? 'livelihood-details-right-sec' : 'livelihood-details-right-sec livelihood-details-optional'}>
                    {livelihoodList?.length > 0 && (
                        <div className='livelihood-details-right-title'>
                            <span>Showing {livelihoodListValue === '' ? livelihoodList.length : searchedDataList.length} livelihood</span>
                        </div>
                    )}
                    <div className='livelihood-header-outer'>
                        <SearchBar className="livelihood-search-bar" placeholder='Search livelihood name' value={livelihoodListValue} handleOnChange={(e) => handleSearchFilter(e.target.value)}
/>
                        <button className='primary-btn livelihood-map-btn' onClick={() => setIsMapLivelihoodCheck(true)}>
                            + Map Livelihood
                        </button>
                    </div>
                </div>
                {livelihoodList.length > 0 ? (
                    <div className='livelihood-details-lower-section'>
                        <Table className='livelihood-details-table pending-table'
                            columns={TableColumns({ handleDelete, setIsModalOpen, setSelectedRow, getValidationList })}
                            data={livelihoodListValue ? searchedDataList : livelihoodList}
                            checkbox={false}
                            selectAll={false}
                        />
                    </div>
                ) : (
                    <div className='livelihood-details-noData-outer'>
                        <div className='livelihood-details-noData-sec'>
                            <img src={NoDataIcon} alt="No Data" />
                            <span>No livelihoods have been mapped to this program yet</span>
                        </div>
                    </div>
                )}
                {toastMessage && <ToastMsg otpMsg={toastMessage} success={true} />}
                {isModalOpen && (
                    <CustomModal onClose={() => setIsModalOpen(false)}>
                        <AssetIncomeModal onClose={() => setIsModalOpen(false)} row={selectedRow} programId={detail?.programId} setIsLoading={setIsLoading} handleAssetChange={handleAssetChange}/>
                    </CustomModal>
                )}
            </>
        )
    );
};

export default LivelihoodTab;
