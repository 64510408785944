import axios from "axios";
import GlobalConfig from '../config/globalconfig';
import { userRoles } from "../config/roles";
import {handleAuditTrail} from "../components/utils/utils"

export const serialize = (obj) => {
  const str = [];
  Object.keys(obj).forEach(p => {
    str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
  });
  /*for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  }*/
  return str.join('&');
};

export const replaceStringWithParams = (str, obj) => {
  Object.keys(obj).forEach(j => {
    const regExp = new RegExp(j, 'g');
    str = str.replace(regExp, obj[j]);
  });

  /*for (const j in obj) {
    const regExp = new RegExp(j, 'g');
    str = str.replace(regExp, obj[j]);
  }*/
  return str;
};

export const isAPICanceled = (e) => {
  if (axios.isCancel(e)) {
    return false;
  }
};

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, btoa(JSON.stringify(value)));
}

export const getLocalStorage = (key) => {
  const localStorageData = localStorage.getItem(key) && JSON.parse(atob(localStorage.getItem(key)))
  return localStorageData;
}

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}
export function sortByKey(array = [], key = null, sortIsAssending, setSortIsAssending = null) {
  let result = [...array];

  if(sortIsAssending) {
    setSortIsAssending && setSortIsAssending(!sortIsAssending)
    result.length > 0 && key && result.sort((a, b) => {
      let fa = typeof (a[key]) === 'number' ? a[key] : a[key].toLowerCase(),
        fb = typeof (b[key]) === 'number' ? b[key] : b[key].toLowerCase();
  
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    return result;
  } else {
    setSortIsAssending &&  setSortIsAssending(!sortIsAssending)
    result.length > 0 && key && result.sort((a, b) => {
      let fa = typeof (a[key]) === 'number' ? a[key] : a[key].toLowerCase(),
        fb = typeof (b[key]) === 'number' ? b[key] : b[key].toLowerCase();
  
      if (fa > fb) {
        return -1;
      }
      if (fa < fb) {
        return 1;
      }
      return 0;
    });
    return result;
  }
}
export function sortDescendByKey(array = [], key = null) {
  const result = [...array];
  result.length > 0 && key && result.sort((a, b) => {
    let fa = typeof (a[key]) === 'number' ? a[key] : a[key].toLowerCase(),
      fb = typeof (b[key]) === 'number' ? b[key] : b[key].toLowerCase();

    if (fa > fb) {
      return -1;
    }
    if (fa < fb) {
      return 1;
    }
    return 0;
  });
  return result;
}
export const searchInArrByKey = (array, key, value) => {
  return array.filter((item) => {
    return (
      item[key]
        .toLowerCase()
        .includes(value.toLowerCase())
    );
  });
}

export const isLocalHost = () => {
  if (!GlobalConfig.isServer() && ['localhost', '0.0.0.0'].includes(window.location.hostname)) {
    return true;
  }
  return false;
};

export const catchErrorHandler = (error, callbackfunc = null, payload = {}) => {

  handleAuditTrail(error, payload)
  if (error && error.response && [401, 409, 406].includes(error.response.status)) {
    // do nothing
  } else {
    typeof (callbackfunc) === 'function' && callbackfunc();
  }
};

export const removeBrTag = (arr) => {
  arr.forEach(e => {
    e.name = e?.name?.toString()?.replace('<b>', '').replace('</b>', '');
  })
  return arr
}

export const isUserManager = () => {
  if (!GlobalConfig.isServer()) {
    const userData = JSON.parse(localStorage.getItem('userAuth')) || '';
    return userData?.roleName === "State" && userData?.typeName === "User Manager"
  } else {
    return false;
  }
}
export const isSuperAdmin = () => {
  if (!GlobalConfig.isServer()) {
    const userData = JSON.parse(localStorage.getItem('userAuth')) || '';
    return userData?.roleName === "National" && userData?.typeName === "Super Admin"
  } else {
    return false;
  }
}

export const getCurrentUser = () => {
  if (!GlobalConfig.isServer()) {
    const userData = JSON.parse(localStorage.getItem('userAuth')) || '';
    if (userData?.roleName === "State" && userData?.typeName === "User Manager")
      return userRoles.userManager
    if (userData?.roleName === "Block" && userData?.typeName === "Project Coordinator")
      return userRoles.BPC
    if (userData?.roleName === "Block" && userData?.typeName === "Project Manager")
      return userRoles.BPM
    if (userData?.roleName === "State" && userData?.typeName === "Project Manager")
      return userRoles.SPM
    // if (userData?.roleName === "State" && userData?.typeName === "Project Manager")
    //   return userRoles.UPCM
    if (userData?.roleName === "District" && userData?.typeName === "Project Coordinator")
      return userRoles.DPC
    if (userData?.roleName === "District" && userData?.typeName === "Project Manager")
      return userRoles.DPM
    if (userData?.roleName === "State" && userData?.typeName === "Project Coordinator")
      return userRoles.SPC
    if (userData?.roleName === "National" && userData?.typeName === "Project Manager")
      return userRoles.NPM
    if (userData?.roleName === "National" && userData?.typeName === "Super Admin")
      return userRoles.SuperAdmin
  }
}

export const isAuthenticated = () => {
  const localStorageData = JSON.parse(localStorage.getItem('userAuth')) || false;
    // const tokenObj = isSoftLogin ? true : readCookie('pwToken');
    const token = localStorage.getItem('token') || false;
    if (token && localStorageData) {
      return true;
    }
    return false;
  
};
export const getBreadCrumbKey = {
  National: "states",
  State: "districts",
  District: "blocks",
  Block: "villages",
};
export const breadcrumbTransformer = (data, role) => {
  let arr = [];
  arr = data?.[getBreadCrumbKey[role]]?.map((item) => ({
    value: item.id,
    id: item.id,
    name: item.name
  }));

  return arr;
};

export const dateTimeSplitter = dt => {
  // input format: 2024-04-02 06:41:13.004
  const monthNumToAlpha = (month) => {
    month.replace()
    switch(month){
      case "1":
      case "01":
        return 'Jan';
      case "2":
      case "02":
        return 'Feb';
      case "03":
      case "3":
        return 'Mar';
      case "4":
      case "04":
        return 'Apr';
      case "5":
      case "05":
        return 'May';
      case "6":
      case "06":
        return 'Jun';
      case "7":
      case "07":
        return 'Jul';
      case "8":
      case "08":
        return 'Aug';
      case "9":
      case "09":
        return 'Sep';
      case "10":
        return 'Oct';
      case "11":
        return 'Nov';
      case "12":
        return 'Dec';
      default: 
    }
  }
  const convert24To12 = hr => {
    const hours = parseInt(hr);
    let formattedHours = hours % 12 || 12;
    let period = hours < 12 ? 'AM' : 'PM';
    return { formattedHours, period };
  } 
  if(dt){
    const [date, time] = dt.split(" ");
    const [year, month, day ] = date?.split("-");
    const [hour, minute] = time?.split(":")
    return { year, month, day, hour: convert24To12(hour)?.formattedHours, AmPm: convert24To12(hour)?.period , minute, monthAlpha: monthNumToAlpha(month)}
  }
}