
import downloadIcon from '../../assets/images/download-icon-deep-blue.svg'
import approve from '../../assets/images/green-dot.png'
import reject from '../../assets/images/red-dot.png'

const TableColumns = () => {

    const modifyYesNoSymbol = str => {
        switch (str) {
            case 'Success':
                return <div className='download-flow-status-wrap'><img src={approve} width={18} height={18} alt='icon' /> {str}</div>
            case 'Failed':
                return <div className='download-flow-status-wrap'><img src={reject} width={18} height={18} alt='icon' /> {str}</div>
            // case 'NOT_MARKED':
            //         return '-'
            default:
                return '--'
        }
    }

    return [
        {
            text: `S.No`,
            formatter: (cell, row, rowIndex) => {
                return (
                rowIndex + 1
                )
            },
        },
        {
            key: "fileName",
            text: `File name`,
            isSort: true
        },
        {
            key: "dateAndTime",
            text: `Date & Time`,
            isSort: false
        },
        {
            key: "recordsCount",
            text: `No.of records`,
            isSort: true
        },
        {
            key: "status",
            text: `Download Status`,
            formatter: (cell, row, rowIndex) => {
                return modifyYesNoSymbol(cell)
            },
            isSort: true
        },
        {
            key: "action",
            text: `Action`,
            isSort: false,
            formatter: (cell, row, rowIndex) => {
                return (
                    <> {row?.status === "Success" ?
                    <div className='download-flow-download-icon-wrap' 
                    // onClick={() => handleDownload(row?.id)}
                    >
                        <img src={downloadIcon} alt="delete" height="16" />
                         <span> Download </span>
                    </div>
                    : "--"
                    }
                    </>
                );
            },
        },

    ]
}
export default TableColumns;