import React from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import './style.scss';

const BeneficiaryFilterListModal = ({ modalParameters: { list , callbackFn }, closePopup }) => {
    
    const handleCallBack = () => {
        callbackFn && callbackFn()
        closePopup()
    }
    return (
        <div>
            <ModalBody>
                <div className='filter-list'>
                { list?.length > 0 && list.map(item => {
                   return <div className='filter-list-item'>{item}</div>
                })}
                </div>
            
            </ModalBody>
            <ModalFooter>
            <button className="secondary-btn ml" onClick={closePopup}>
                Cancel
            </button>
            <button className="primary-btn" onClick={handleCallBack} >
                Reset Filter
            </button>
            </ModalFooter>
        </div>
    )
}
export default BeneficiaryFilterListModal