import React, { useState, useEffect } from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import './style.scss';
import CustomDropdown from '../../CustomDropdown';
import { commonService } from '../../../../services/common';
import GlobalConfig from '../../../../config/globalconfig';
import { useDispatch, useSelector } from 'react-redux';
import { setApiError, showLoaderAction } from '../../../../storeManager/common/actions';
import { catchErrorHandler } from '../../../../services/commonUtils';
import { openModal } from '../../../../storeManager/modal/actions';
import { MODALS } from '../../../../storeManager/modal/constants';
import Table from '../../Table';
import TableColumns from './columns';
import FallbackComp from '../../FallbackComp';



const UpcmSelectionModal = ({ modalParameters: { blockId, selectedDidis, callBackFn, activeUpcmId, isReassign = false, modalBodyText }, closePopup }) => {
    const dispatch = useDispatch();
    const { isApiError } = useSelector(state => state.commonReducer)
    const [selectedUpcm, setSelectedUpcm] = useState(null)
    const [upcmOptionList, setUpcmOptionList] = useState([]);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [tableData, setTableData] = useState([])
    const [isConfirm, setIsConfirm] = useState(false)
    const [fallbackMsg, setFallbackMsg] = useState(null)
    // const [tolaIds, setTolaIds] = useState([])
    useEffect(() => {
        fetchUpcmList(blockId)
    }, [])
    const fetchUpcmList = async (id) => {
        dispatch(showLoaderAction(true));
        try {
            const response = await commonService.getBlockUpcmList(id);
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                let updatedUpcmList = res?.data;
                if(isReassign)
                    updatedUpcmList = updatedUpcmList?.filter(x => x.id !== activeUpcmId )
                const updmList = updatedUpcmList?.map(item => ({
                    value: item.id,
                    htmlName: `<div class="upcm-list"><span class="op-name">${item.upcmName} (${item.phoneNumber})</span> <i class="didi-icon"></i> <span class="count">${item.totalDidiCount}</span></div>`,
                    name: `${item.upcmName}`,
                    key: item.id,
                    upcmName: item.upcmName,
                    count: item.totalDidiCount
                }))
                setUpcmOptionList(updmList)
            }
            else
                dispatch(setApiError())
            dispatch(showLoaderAction(false));

        } catch (error) {
            dispatch(showLoaderAction(false));
            console.error(error)
            catchErrorHandler(
                error, 
                dispatch(setApiError()),
                {
                    actionType: "SELECT",
                    actionStatusType:"ERROR"
                }
            );
        }
    };
    const handleUpcmUpdate = async () => {
        dispatch(showLoaderAction(true));
        const payload = {
            response: tableData,
            targetUpcmId: selectedUpcm?.value
        }
        try {
            const response = await commonService.updateUpcmOnConfirm(payload);
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                // setSubmitSuccessful(true)
                setIsConfirm(false)
                // callBackFn()
                dispatch(openModal(MODALS.INFO_MODAL,
                    {
                        parentClass: "center-modal confirmation-modal",
                        info: /*${tableData?.length}*/ `${(new Set(tableData.map(item => item.tolaName))).size} Tolas are assigned successfully to ${selectedUpcm?.upcmName}.`,
                        // modalTitle: '',
                        showActionBtn: false
                    }))
            }
            else
                dispatch(setApiError())
            dispatch(showLoaderAction(false));

        } catch (error) {
            dispatch(showLoaderAction(false));
            console.error(error)
            catchErrorHandler(
                error, 
                dispatch(setApiError()),
                {
                    actionType: "UPDATE",
                    actionStatusType:"ERROR"
                }
            );
        }
    }
    const updatedUpcm = async () => {
        dispatch(showLoaderAction(true));
        setFallbackMsg(null)
        let payload = {};
        if (isReassign)
            payload = {
                "beneficiaryIds": selectedDidis,
                "sourceUpcmId": activeUpcmId,
                "action": "REASSIGN",
                "targetUpcmId": selectedUpcm?.value
            }
        else
            payload = {
                "beneficiaryIds": selectedDidis,
                "sourceUpcmId": selectedUpcm?.value,
                "action": "ASSIGN",
                "targetUpcmId": ""
            }
        try {
            const response = await commonService.updateUpcmDidis(payload);
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS && Array.isArray(res?.data)) {
                // setSubmitSuccessful(true)
                callBackFn()
                if (Array.isArray(res?.data) && res?.data?.length > 0) {
                    setTableData(res?.data)
                    setIsConfirm(true)
                }
                else {
                    setIsConfirm(false)
                    dispatch(openModal(MODALS.INFO_MODAL,
                        {
                            parentClass: "center-modal confirmation-modal",
                            info: `${selectedDidis?.length} didis are assigned successfully to ${selectedUpcm?.upcmName}.`,
                            // modalTitle: '',
                            showActionBtn: false
                        }))
                }
            }
            else{
                dispatch(setApiError())
                setFallbackMsg(res?.data)
            }
            dispatch(showLoaderAction(false));

        } catch (error) {
            dispatch(showLoaderAction(false));
            console.error(error)
            catchErrorHandler(
                error, 
                dispatch(setApiError()),
                {
                    actionType: "UPDATE",
                    actionStatusType:"ERROR",
                    dataChangeDetails: [payload]
                }
            );
        }
    };
    const handleUpcmChange = value => {

        setSelectedUpcm(upcmOptionList?.find(x => x.value === value))
    }

    return submitSuccessful ?
        <div>
            <ModalBody>
                <p>Didi assigned successfully</p>
            </ModalBody>
        </div>
        :
        <div>
            <ModalBody>
                { isApiError && <FallbackComp className="fallback-red" message={fallbackMsg} /> }
                {
                    isConfirm ? <>
                        <p>{modalBodyText} <b>{selectedUpcm?.upcmName}</b> ?</p>
                        <br />
                        <div className='upcm-confirmation-list-outer'>
                            <Table
                                className='pending-table upcm-confirm-list'
                                columns={TableColumns()}
                                data={tableData}
                                checkbox={false}
                            />
                        </div>
                    </>
                        :
                        <>
                            {upcmOptionList?.length > 0 ? <CustomDropdown
                                style={{ "width": "100%" }}
                                showSearchBar={true}
                                placeHolder="Select UPCM"
                                onChange={(value) => handleUpcmChange(value)}
                                options={upcmOptionList}
                                value={selectedUpcm?.value}
                                className="upcm-selection-dropdown"
                                isHtmlOption={true}
                            />
                                :
                                <p>Sorry, there is no UPCM available at the moment.</p>
                            }
                            <br />
                            {(selectedUpcm && isReassign) ?
                                <p><span className="brown-bold">{selectedDidis?.length} more Didis </span>are selected for the {selectedUpcm?.upcmName}. If any groups are created for the didis, it will be removed.
                                    Do you want to continue for the reassignment ?</p>
                                :
                                (selectedUpcm && !isReassign) ? <p>{selectedUpcm?.upcmName} currently has <span className="brown-bold">{selectedUpcm?.count} didis</span> assigned. Assigning these <span className="brown-bold">{selectedDidis?.length} didis</span> will total {selectedUpcm?.upcmName}'s count to {selectedUpcm?.count + selectedDidis?.length}</p>
                                    : null
                            }
                        </>
                }
            </ModalBody>
            <ModalFooter>
                <button className="secondary-btn ml" onClick={closePopup}>
                    Cancel
                </button>
                <button disabled={!selectedUpcm} className="primary-btn" onClick={() => { isConfirm ? handleUpcmUpdate() : updatedUpcm() }}>
                    Proceed
                </button>
            </ModalFooter>
        </div>


}
export default UpcmSelectionModal