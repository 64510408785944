import React, { useEffect, useRef, useState } from 'react'
import { downloadService } from './downloadService'
import { useDispatch } from 'react-redux';
import { setApiError } from '../../../storeManager/common/actions';
import DownloadInprogressSection from '../DownloadInprogressSection';


const DownloadComp = (props)=> {
    const dispatch = useDispatch();
    // const [isLoading, setIsLoading] = useState(true); 
    const [downloadCompletedStatus, setDownloadCompletedStatus] = useState("");
    const [loadingDots, setLoadingDots] = useState([]);
    const intervalRef = useRef(null);
    const dotIntervalRef = useRef(null);

    useEffect(() => {
        let multiDownloadData;
        let multiDownloadType;
    
        const fetchDownloadData = setTimeout(() => {
          multiDownloadData = JSON.parse(localStorage.getItem("multiDownloadData")) || "";
          multiDownloadType = JSON.parse(localStorage.getItem("multiDownloadType")) || multiDownloadData?.type || "";
    
          if (multiDownloadData && multiDownloadType) {
            intervalRef.current = setInterval(() => {
              checkFileReadyStatus(multiDownloadData.data, multiDownloadType);
            }, 8000);
    
            // Start adding dots every 2 seconds
            dotIntervalRef.current = setInterval(() => {
              setLoadingDots((prevDots) => [...prevDots, '.']);
            }, 8000);
          }
        }, 500);
    
        return () => {
          clearTimeout(fetchDownloadData);
          clearInterval(intervalRef.current);
          clearInterval(dotIntervalRef.current);
        };
      }, []);

    const checkFileReadyStatus = async (multiDownloadData, multiDownloadType) => {
        try {
            const res = await downloadService.getDownloadAPIStatus(multiDownloadData, multiDownloadType);
            setDownloadCompletedStatus(res?.status)
            if(res?.status === 200) {
                clearInterval(intervalRef.current);
                clearInterval(dotIntervalRef.current);
                setDownloadCompletedStatus(200);
                setTimeout(()=>{
                    tabClose()
                },2000)
                const url = URL.createObjectURL(res?.data);
                const link = document.createElement('a');
                link.href = url;
                const contentDisposition = res.headers['content-disposition'];
                const parts = contentDisposition?.split(';');
                const filenamePart = parts[1];
                const sheetName = filenamePart?.split('=')[1].trim();
                const filename = sheetName ? sheetName : `a.sheet.xlsx`;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                // Clean up the URL object
                window.URL.revokeObjectURL(url);
                // clearInterval(checkApiStatus)
            } else {
                setDownloadCompletedStatus(res.status);
            }
  
            // setIsLoading(false)
      
        } catch (error) {
            // setIsLoading(false)
            // catchErrorHandler(error, dispatch(setApiError()));
            dispatch(setApiError())
            console.error(error)
        }
      }
      const tabClose = () =>{
        localStorage.removeItem("multiDownloadData");
        localStorage.removeItem("multiDownloadType");
        window.close();
      }

  return (
    <div className='download-progress'>
    <DownloadInprogressSection
      downloadCompletedStatus={downloadCompletedStatus}
      loadingDots={loadingDots}
      tabClose={tabClose}
    />
  </div>
  )
}

export default DownloadComp