import React, { useEffect, useState } from 'react'
import Header from '../../components/Common/Header'
import {
    TabContent, TabPane, Nav,
    NavItem, NavLink
} from 'reactstrap';
import './style.scss'
import TabsComponent from './TabsComponent'
import TabHead from '../../components/Common/TabHead';
import Programs from './Programs';
import { setApiError } from '../../storeManager/common/actions';
import { catchErrorHandler } from '../../services/commonUtils';
import GlobalConfig from '../../config/globalconfig';
import { useDispatch } from 'react-redux';
import { dashBoardService } from '../Dashboard/dashoardService';
import Livelihood from './Livelihood';
import CreateLivelihood from './Livelihood/CreateLivelihood';
import { commonService } from '../../services/common';

const SuperAdmin = () => {
    const [currentActiveTab, setCurrentActiveTab] = useState('1');
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [tabData, setTabData] = useState([]);
    const [isCreateLivelihoodPage, setIsCreateLivelihoodPage] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [editData, setEditData] = useState({})

    const dispatch = useDispatch();

    const fetchProgram = async (payload) => {
        setIsLoading(true);
        try {
          const res = await dashBoardService.getProgramListData(payload);
          const response = res?.data?.data?.details || [];
          if (res?.data.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
            setTableData(response);
          } else {
            dispatch(setApiError());
          }
          setIsLoading(false);
        } catch (error) {
          console.error(error);
          catchErrorHandler(error, dispatch(setApiError()));
          setIsLoading(false);
        }
      };

      const fetchTabData = async () => {
        setIsLoading(true);
        try {
          const res = await commonService.fetchProgramWidgetList(0);
          const response = res?.data?.data;
          if (res?.data.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
            const filteredTabList = response?.filter(item => 
              item?.name === "Programs" || item?.name === "Livelihoods"
          );
            setTabData(filteredTabList)
          } else {
            dispatch(setApiError());
          }
          setIsLoading(false);
        } catch (error) {
          console.error(error);
          catchErrorHandler(error, dispatch(setApiError()));
          setIsLoading(false);
        }
      };

    const toggle = tab => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
      }
      const  handleCreateLivelihoodPage = (isDetail, isEdit, row) => {
        setIsCreateLivelihoodPage(isDetail)
        setIsEdit(isEdit)
        let data = {
            "assets": row?.assets, 
            "products": row?.products,
            "events": row?.events, 
            "livelihoodName": row?.type,
            "displayName": row?.displayName,
            "programLivelihoodName": row?.name,
            "image": row?.image,
            "id": row?.id
        }
        setEditData(data)
    }
    useEffect(()=>{
        fetchTabData()
    },[])

    return (
        <div className="inner-page super-admin fixed-header">
            <Header />
            <div className='container'>
                <div className='super-admin-outer-tab-section'>
                    {tabData.length > 0 && <div className='super-admin-tab'> <Nav tabs>
                        <NavItem>
                            <NavLink onClick={() => toggle('1')}>
                                <TabsComponent isActive={currentActiveTab === '1'} count={tabData ? tabData[0]?.count : '0'} title={tabData[0]?.name} />
                            </NavLink>
                        </NavItem>
                         <NavItem>
                            <NavLink onClick={() => toggle('2')} >
                                <TabsComponent isActive={currentActiveTab === '2'} count={tabData ? tabData[1]?.count : '0'} title={tabData[1]?.name} />
                            </NavLink>
                        </NavItem> 
                    </Nav></div>}
                    <TabContent className={`program-tab-container active-${currentActiveTab}`} activeTab={currentActiveTab}>
                        <TabPane tabId="1">
                            {currentActiveTab === '1' && <Programs tableData={tableData} setTableData={setTableData} fetchProgram={fetchProgram} setIsLoading={setIsLoading} isLoading={isLoading} />}
                        </TabPane>
                         <TabPane tabId="2">
                            {currentActiveTab === '2' && (<>{isCreateLivelihoodPage ? <CreateLivelihood isEdit={isEdit} handleCreateLivelihoodPage={handleCreateLivelihoodPage} editData={editData} setToastMessage={setToastMessage} /> : <Livelihood handleCreateLivelihoodPage={handleCreateLivelihoodPage} toastMessage={toastMessage} setToastMessage={setToastMessage} fetchTabData={fetchTabData} />}</>)}
                        </TabPane> 
                    </TabContent>
                </div>
            </div>
        </div>
    )
}

export default SuperAdmin